// extracted by mini-css-extract-plugin
var _1 = "VmuPC7UVk3jcyShKFhJ9";
var _2 = "#76F366";
var _3 = "#EB8632";
var _4 = "#ECC834";
var _5 = "#21FC87";
var _6 = "#EA4A30";
var _7 = "#C7EA45";
var _8 = "#272B2B";
var _9 = "#BDBDBD";
var _a = "#7D7D7D";
var _b = "#6A0DAD";
var _c = "#3A65FF";
var _d = "#4A90E2";
var _e = "#ffffff";
var _f = "Sy0Nu7miNo_G4D0nOIfx";
var _10 = "pF4dpCupA8jqlE5EKle1";
var _11 = "LqbhvnfNbL3gkwWE5UuE";
var _12 = "TjsoNxGldNz04mSvUudg";
var _13 = "ByOpUmNuYH303pBxcr5_";
var _14 = "DxptX1rCK0iwPhyCMyYQ";
var _15 = "ihAPHg7lmI4PmChp9jpw";
var _16 = "1200px";
var _17 = "992px";
var _18 = "1366px";
var _19 = "768px";
var _1a = "1475px";
var _1b = "576px";
var _1c = "1600px";
var _1d = "320px";
var _1e = "2500px";
var _1f = "sPFc8zWILqveMkQ7ZVqC";
export { _1 as "base-link", _2 as "colorGradeEX", _3 as "colorGradeFR", _4 as "colorGradeGD", _5 as "colorGradeOU", _6 as "colorGradePR", _7 as "colorGradeVG", _8 as "colorNeutral", _9 as "colorNeutralLighest", _a as "colorNeutralLight", _b as "colorNonOwnerSweetLine", _c as "colorOwnerSweetLine", _d as "colorRegularLinks", _e as "colorWhite", _f as "content", _10 as "inputContainer", _11 as "listContainer", _12 as "product-block-grid", _13 as "product-block-subgrid", _14 as "reset-button", _15 as "rightContainer", _16 as "screenlg", _17 as "screenmd", _18 as "screenml", _19 as "screensm", _1a as "screenxlg", _1b as "screenxs", _1c as "screenxxlg", _1d as "screenxxs", _1e as "screenxxxlg", _1f as "single-page-responsive-font-size" }
export default { "base-link": _1, "colorGradeEX": _2, "colorGradeFR": _3, "colorGradeGD": _4, "colorGradeOU": _5, "colorGradePR": _6, "colorGradeVG": _7, "colorNeutral": _8, "colorNeutralLighest": _9, "colorNeutralLight": _a, "colorNonOwnerSweetLine": _b, "colorOwnerSweetLine": _c, "colorRegularLinks": _d, "colorWhite": _e, "content": _f, "inputContainer": _10, "listContainer": _11, "product-block-grid": _12, "product-block-subgrid": _13, "reset-button": _14, "rightContainer": _15, "screenlg": _16, "screenmd": _17, "screenml": _18, "screensm": _19, "screenxlg": _1a, "screenxs": _1b, "screenxxlg": _1c, "screenxxs": _1d, "screenxxxlg": _1e, "single-page-responsive-font-size": _1f }
