import { Subject } from 'rxjs';
import { DriveAPI } from '@/drive/api/DriveAPI';
import { DriveItemType } from '@/drive/entities/DriveItem';
import { driveFormToRequestData, mergeDriveItemWithForm, } from '@/drive/types/driveItemModalFormData';
import { pinnedItemsStore as globalPinnedItemsStore } from '@/forwarding/stores/PinnedItemsStore';
import { createPinnedDriveItem } from '@/forwarding/types/pinnedItem';
import { DriveModifierEventType, OptimisticUpdatePhase } from './DriveModifierStore.types';
export class DriveModifierStore {
    pinnedItemsStore;
    driveAPI;
    eventSubject = new Subject();
    lastTemporaryId = -1;
    event$ = this.eventSubject.asObservable();
    constructor(pinnedItemsStore, driveAPI = new DriveAPI()) {
        this.pinnedItemsStore = pinnedItemsStore;
        this.driveAPI = driveAPI;
    }
    async deleteItem(item) {
        const pinnedItem = createPinnedDriveItem(item);
        if (this.pinnedItemsStore.isPinned(pinnedItem)) {
            this.pinnedItemsStore.unpin(pinnedItem.id);
        }
        this.eventSubject.next({
            type: DriveModifierEventType.Delete,
            phase: OptimisticUpdatePhase.Apply,
            item,
        });
        try {
            await this.driveAPI.deleteById(item.id);
            this.eventSubject.next({
                type: DriveModifierEventType.Delete,
                phase: OptimisticUpdatePhase.Success,
                item,
            });
        }
        catch (error) {
            this.eventSubject.next({ type: DriveModifierEventType.Delete, phase: OptimisticUpdatePhase.Error });
            throw error;
        }
    }
    async create(form, type, parentId) {
        const isUrl = type === DriveItemType.Url;
        const data = driveFormToRequestData(form, { isUrl });
        const temporaryId = this.generateTemporaryId();
        const temporaryItem = mergeDriveItemWithForm({ id: temporaryId, type }, form);
        this.eventSubject.next({
            type: DriveModifierEventType.Create,
            phase: OptimisticUpdatePhase.Apply,
            temporaryItem,
            parentId,
        });
        const res = await this.driveAPI.batchCreate([{ type, parent: parentId, ...data }]);
        if (res.hasErrors()) {
            this.eventSubject.next({ type: DriveModifierEventType.Create, phase: OptimisticUpdatePhase.Error });
        }
        else {
            const backendItem = res.getItems()[0];
            this.eventSubject.next({
                type: DriveModifierEventType.Create,
                parentId,
                phase: OptimisticUpdatePhase.Success,
                temporaryItem,
                backendItem,
            });
        }
        return res;
    }
    async updateItem(item, form) {
        const updatedItem = mergeDriveItemWithForm(item, form);
        this.eventSubject.next({
            type: DriveModifierEventType.Update,
            phase: OptimisticUpdatePhase.Apply,
            originalItem: item,
            updatedItem,
        });
        const req = {
            id: item.id,
            ...driveFormToRequestData(form, { isUrl: item.type === DriveItemType.Url }),
        };
        const res = await this.driveAPI.batchUpdate([req]);
        if (res.hasErrors()) {
            this.eventSubject.next({ type: DriveModifierEventType.Update, phase: OptimisticUpdatePhase.Error });
        }
        else {
            this.eventSubject.next({
                type: DriveModifierEventType.Update,
                phase: OptimisticUpdatePhase.Success,
                originalItem: item,
                updatedItem,
            });
        }
        return res;
    }
    async addBatch(config, form, parentId) {
        const res = await this.driveAPI.batchCreate(config.getDriveAddReqData().map((req) => ({ ...req, parent: parentId, ...config.formToRequestData(form) })));
        if (res.hasErrors()) {
            this.eventSubject.next({ type: DriveModifierEventType.BatchAdd, phase: OptimisticUpdatePhase.Error });
        }
        else {
            this.eventSubject.next({
                type: DriveModifierEventType.BatchAdd,
                phase: OptimisticUpdatePhase.Success,
                backendItems: res.getItems(),
                parentId,
            });
        }
        return res;
    }
    async moveBatch(config, form, parentId) {
        const res = await this.driveAPI.batchUpdate(config.getDriveMoveReqData().map((req) => ({ ...req, parent: parentId, ...config.formToRequestData(form) })));
        if (res.hasErrors()) {
            this.eventSubject.next({ type: DriveModifierEventType.BatchMove, phase: OptimisticUpdatePhase.Error });
        }
        else {
            this.eventSubject.next({
                type: DriveModifierEventType.BatchMove,
                phase: OptimisticUpdatePhase.Success,
                backendItems: res.getItems(),
                parentId,
            });
        }
        return res;
    }
    generateTemporaryId() {
        this.lastTemporaryId = this.lastTemporaryId - 1;
        return this.lastTemporaryId;
    }
}
export const driveModifierStore = new DriveModifierStore(globalPinnedItemsStore);
