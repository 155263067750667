// extracted by mini-css-extract-plugin
var _1 = "V4w4isaEMD0ZeWSnuGvE";
var _2 = "aDoMMNHqhCqUXtoj5QZB";
var _3 = "#76F366";
var _4 = "#EB8632";
var _5 = "#ECC834";
var _6 = "#21FC87";
var _7 = "#EA4A30";
var _8 = "#C7EA45";
var _9 = "#272B2B";
var _a = "#BDBDBD";
var _b = "#7D7D7D";
var _c = "#6A0DAD";
var _d = "#3A65FF";
var _e = "#4A90E2";
var _f = "#ffffff";
var _10 = "lINQn76Vj7A4YEzIWYsA";
var _11 = "rQlP5xxUxQmuxoS1JGPQ";
var _12 = "G1HPRWD1f9XHuf_sUKvw";
var _13 = "tbeawa5ClYG7IfAGyaeA";
var _14 = "YEuimFyKAoq7h13lf_49";
var _15 = "G0LvnjUkOJXDFNX4M3W4";
var _16 = "ZIfEMjALL2Pw0EZs7JR8";
var _17 = "ffCDq5XDYfyZWLlvYbux";
var _18 = "xcy9eEUlh0LL4YM321ZS";
var _19 = "FYnvGJxioZx5f9cmSYka";
var _1a = "1200px";
var _1b = "992px";
var _1c = "1366px";
var _1d = "768px";
var _1e = "1475px";
var _1f = "576px";
var _20 = "1600px";
var _21 = "320px";
var _22 = "2500px";
var _23 = "PJVPXd9hswq2i_NjFqJG";
var _24 = "mB77EB4liNkDhIAFs1wI";
export { _1 as "base-link", _2 as "center", _3 as "colorGradeEX", _4 as "colorGradeFR", _5 as "colorGradeGD", _6 as "colorGradeOU", _7 as "colorGradePR", _8 as "colorGradeVG", _9 as "colorNeutral", _a as "colorNeutralLighest", _b as "colorNeutralLight", _c as "colorNonOwnerSweetLine", _d as "colorOwnerSweetLine", _e as "colorRegularLinks", _f as "colorWhite", _10 as "pageError", _11 as "pageErrorCode", _12 as "pageErrorDescription", _13 as "pageErrorDescriptionHasLinks", _14 as "pageErrorDescriptionLinks", _15 as "pageErrorImage", _16 as "pageErrorWrapper", _17 as "product-block-grid", _18 as "product-block-subgrid", _19 as "reset-button", _1a as "screenlg", _1b as "screenmd", _1c as "screenml", _1d as "screensm", _1e as "screenxlg", _1f as "screenxs", _20 as "screenxxlg", _21 as "screenxxs", _22 as "screenxxxlg", _23 as "single-page-responsive-font-size", _24 as "withMarginBottom" }
export default { "base-link": _1, "center": _2, "colorGradeEX": _3, "colorGradeFR": _4, "colorGradeGD": _5, "colorGradeOU": _6, "colorGradePR": _7, "colorGradeVG": _8, "colorNeutral": _9, "colorNeutralLighest": _a, "colorNeutralLight": _b, "colorNonOwnerSweetLine": _c, "colorOwnerSweetLine": _d, "colorRegularLinks": _e, "colorWhite": _f, "pageError": _10, "pageErrorCode": _11, "pageErrorDescription": _12, "pageErrorDescriptionHasLinks": _13, "pageErrorDescriptionLinks": _14, "pageErrorImage": _15, "pageErrorWrapper": _16, "product-block-grid": _17, "product-block-subgrid": _18, "reset-button": _19, "screenlg": _1a, "screenmd": _1b, "screenml": _1c, "screensm": _1d, "screenxlg": _1e, "screenxs": _1f, "screenxxlg": _20, "screenxxs": _21, "screenxxxlg": _22, "single-page-responsive-font-size": _23, "withMarginBottom": _24 }
