import { BILLING_DASHBOARD_PAGE } from '@/billing/constants/pages';
import { CATALOG_DIAMONDS_COLORLESS_EDIT_PAGE, CATALOG_DIAMONDS_COLORLESS_PAGE, CATALOG_DIAMONDS_FANCY_EDIT_PAGE, CATALOG_DIAMONDS_FANCY_PAGE, CATALOG_JEWELRY_EDIT_PAGE, CATALOG_JEWELRY_PAGE, CATALOG_LGD_COLORLESS_PAGE, CATALOG_LGD_FANCY_EDIT_PAGE, CATALOG_LGD_ROUGH_EDIT_PAGE, CATALOG_LGD_ROUGH_PAGE, CATALOG_ROUGH_EDIT_PAGE, CATALOG_ROUGH_PAGE, COLLECTIONS_PAGE, MULTI_PROJECTS_PAGE, PROJECTS_PAGE, SHARED_WITH_ME_PROJECTS_PAGE, TASKS_PAGE, } from '@/common/constants/pages';
import { LGD, MANAGER, RENDER_MANAGER, securityManager } from '@/common/services/SecurityManager';
import { SERVICES_PAGE } from '@/conversion/constants/pages';
import { toTreeItem } from '@/drive/entities/SectionItem';
import { DriveSection } from '@/drive/types/driveSection';
const myDriveSection = {
    title: 'Cutwise Drive',
    id: DriveSection.MyDrive,
    collapsed: true,
    icon: 'cutwise-drive',
};
export function createMyDriveSection() {
    return toTreeItem(myDriveSection);
}
export function createSidebarTreeSections(user) {
    const sections = [
        {
            title: 'Public Cutwise Catalogue',
            id: DriveSection.Public,
            children: [
                {
                    title: 'Natural Diamonds',
                    id: DriveSection.PublicDiamonds,
                    children: [
                        {
                            title: 'Polished Colorless',
                            icon: 'diamond',
                            id: DriveSection.PublicColorless,
                            url: CATALOG_DIAMONDS_COLORLESS_PAGE,
                        },
                        {
                            title: 'Polished Fancy Color',
                            icon: 'diamond',
                            iconClassName: 'accent',
                            id: DriveSection.PublicFancy,
                            url: CATALOG_DIAMONDS_FANCY_PAGE,
                        },
                        {
                            title: 'Rough',
                            icon: 'rough',
                            id: DriveSection.PublicRough,
                            url: CATALOG_ROUGH_PAGE,
                        },
                    ],
                },
                {
                    title: 'Lab-Grown Diamonds',
                    id: DriveSection.PublicLgd,
                    children: [
                        {
                            title: 'Polished Colorless',
                            icon: 'diamond',
                            id: DriveSection.PublicLgdColorless,
                            url: CATALOG_LGD_COLORLESS_PAGE,
                        },
                        {
                            title: 'Polished Fancy Color',
                            icon: 'diamond',
                            iconClassName: 'accent',
                            id: DriveSection.PublicLgdFancy,
                            url: CATALOG_DIAMONDS_FANCY_PAGE,
                        },
                        {
                            title: 'Rough',
                            icon: 'rough',
                            id: DriveSection.PublicLgdRough,
                            url: CATALOG_LGD_ROUGH_PAGE,
                        },
                    ],
                },
                {
                    title: 'Jewelry',
                    icon: 'jewelry',
                    id: DriveSection.PublicJewelry,
                    url: CATALOG_JEWELRY_PAGE,
                },
                { title: 'Demo', id: DriveSection.DemoCollections, url: COLLECTIONS_PAGE },
            ],
        },
        {
            id: DriveSection.PinnedItems,
            title: '',
            icon: 'pin-filled',
        },
        {
            title: 'My Collections',
            id: DriveSection.PrivateCollections,
            icon: 'like',
        },
        {
            title: 'Light-Spectrum Demo',
            id: DriveSection.Spectrum,
            icon: 'metrics-color-material',
        },
        myDriveSection,
    ];
    sections.push(createPrivateRoot(user));
    return sections.map(toTreeItem);
}
function createPrivateRoot(user) {
    const privateRoot = {
        title: '',
        collapsed: false,
        id: DriveSection.PrivateRoot,
        children: [
            {
                title: 'My Profile',
                id: DriveSection.Profile,
                icon: 'user-profile',
                url: '/profile',
            },
        ],
    };
    if (!user?.flags?.disableBilling && securityManager.isGranted(MANAGER)) {
        privateRoot.children.push({
            title: 'Billing Dashboard',
            id: DriveSection.Billing,
            url: BILLING_DASHBOARD_PAGE,
        });
    }
    const projectsSection = {
        title: 'Projects',
        id: DriveSection.PrivateProjects,
        children: [],
    };
    if (user?.flags?.allowMultistage) {
        projectsSection.children.push({
            title: 'Multistage',
            id: DriveSection.Multistage,
            url: MULTI_PROJECTS_PAGE,
            icon: 'multistage',
        });
    }
    if (securityManager.isGranted(RENDER_MANAGER)) {
        projectsSection.children.push({
            title: 'Owned by Me',
            id: DriveSection.MyProjects,
            url: PROJECTS_PAGE,
            icon: 'project',
        });
    }
    projectsSection.children.push({
        title: 'Shared by Me',
        id: DriveSection.SharedProjects,
        url: SHARED_WITH_ME_PROJECTS_PAGE,
        icon: 'project-shared',
    });
    privateRoot.children.push(projectsSection);
    if (securityManager.isGranted(MANAGER) && !securityManager.isGranted(LGD)) {
        privateRoot.children.push({
            title: 'Natural Diamonds',
            id: DriveSection.PrivateDiamonds,
            children: [
                {
                    title: 'Colorless',
                    icon: 'diamond',
                    id: DriveSection.PrivateColorless,
                    url: CATALOG_DIAMONDS_COLORLESS_EDIT_PAGE,
                },
                {
                    title: 'Fancy Color',
                    icon: 'diamond',
                    id: DriveSection.PrivateFancy,
                    url: CATALOG_DIAMONDS_FANCY_EDIT_PAGE,
                    iconClassName: 'accent',
                },
                {
                    title: 'Rough',
                    icon: 'rough',
                    id: DriveSection.PrivateRough,
                    url: CATALOG_ROUGH_EDIT_PAGE,
                },
            ],
        });
    }
    if (securityManager.isGranted(MANAGER) && securityManager.isGranted(LGD)) {
        privateRoot.children.push({
            title: 'Lab-Grown Diamonds',
            id: DriveSection.PrivateLgd,
            children: [
                {
                    title: 'Colorless',
                    icon: 'diamond',
                    id: DriveSection.PrivateLgdColorless,
                    url: CATALOG_LGD_COLORLESS_PAGE,
                },
                {
                    title: 'Fancy Color',
                    id: DriveSection.PrivateLgdFancy,
                    url: CATALOG_LGD_FANCY_EDIT_PAGE,
                    icon: 'diamond',
                    iconClassName: 'accent',
                },
                {
                    title: 'Rough',
                    icon: 'rough',
                    id: DriveSection.PrivateLgdRough,
                    url: CATALOG_LGD_ROUGH_EDIT_PAGE,
                },
            ],
        });
    }
    privateRoot.children.push({
        title: 'Jewelry',
        icon: 'jewelry',
        id: DriveSection.PrivateJewelry,
        url: CATALOG_JEWELRY_EDIT_PAGE,
    }, {
        title: 'Services',
        id: DriveSection.Services,
        url: SERVICES_PAGE,
        icon: 'file-conversion',
    }, {
        title: 'My Tasks',
        url: TASKS_PAGE,
        id: DriveSection.Tasks,
        icon: 'pending',
    });
    return privateRoot;
}
