import { jsx as _jsx } from "react/jsx-runtime";
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { iOS } from '@/common/components/SortableTree/SortableTree.utils';
import { TreeItemView } from './TreeItemView';
const animateLayoutChanges = ({ isSorting, wasDragging }) => !(isSorting || wasDragging);
export function SortableTreeItem({ id, depth, ...props }) {
    const { attributes, isDragging, isSorting, listeners, setDraggableNodeRef, setDroppableNodeRef, transform, transition, } = useSortable({
        id,
        animateLayoutChanges,
    });
    const style = {
        transform: CSS.Translate.toString(transform),
        transition,
    };
    return (_jsx(TreeItemView, { ref: setDraggableNodeRef, wrapperRef: setDroppableNodeRef, style: style, depth: depth, ghost: isDragging, disableSelection: iOS, disableInteraction: isSorting, handleProps: {
            ...attributes,
            ...listeners,
        }, ...props }));
}
