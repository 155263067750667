// extracted by mini-css-extract-plugin
var _1 = "SE1hMoMZpuqe8RAKBFbg";
var _2 = "#76F366";
var _3 = "#EB8632";
var _4 = "#ECC834";
var _5 = "#21FC87";
var _6 = "#EA4A30";
var _7 = "#C7EA45";
var _8 = "#272B2B";
var _9 = "#BDBDBD";
var _a = "#7D7D7D";
var _b = "#6A0DAD";
var _c = "#3A65FF";
var _d = "#4A90E2";
var _e = "#ffffff";
var _f = "vLjFxZ7q4gKD6CMtWuo3";
var _10 = "m_5LtkVB983fiBnLzPXf";
var _11 = "_jzgGWat11vGbBp0qLt2";
var _12 = "XXprPBEDaZqF5Hce1Sfe";
var _13 = "Qkc1UHXjeFQoVHYoes3e";
var _14 = "JnWEwxn7OOBruJwlgU4w";
var _15 = "TD6yRvzWSjUdaSKvhE4Q";
var _16 = "1200px";
var _17 = "992px";
var _18 = "1366px";
var _19 = "768px";
var _1a = "1475px";
var _1b = "576px";
var _1c = "1600px";
var _1d = "320px";
var _1e = "2500px";
var _1f = "SePXkYYBZSaqiLSsd3NK";
var _20 = "u7ea0Yi4VKjzCfjejAUw";
export { _1 as "base-link", _2 as "colorGradeEX", _3 as "colorGradeFR", _4 as "colorGradeGD", _5 as "colorGradeOU", _6 as "colorGradePR", _7 as "colorGradeVG", _8 as "colorNeutral", _9 as "colorNeutralLighest", _a as "colorNeutralLight", _b as "colorNonOwnerSweetLine", _c as "colorOwnerSweetLine", _d as "colorRegularLinks", _e as "colorWhite", _f as "driveItemModal", _10 as "formContainer", _11 as "previewContainer", _12 as "product-block-grid", _13 as "product-block-subgrid", _14 as "reset-button", _15 as "rightContainer", _16 as "screenlg", _17 as "screenmd", _18 as "screenml", _19 as "screensm", _1a as "screenxlg", _1b as "screenxs", _1c as "screenxxlg", _1d as "screenxxs", _1e as "screenxxxlg", _1f as "single-page-responsive-font-size", _20 as "treeContainer" }
export default { "base-link": _1, "colorGradeEX": _2, "colorGradeFR": _3, "colorGradeGD": _4, "colorGradeOU": _5, "colorGradePR": _6, "colorGradeVG": _7, "colorNeutral": _8, "colorNeutralLighest": _9, "colorNeutralLight": _a, "colorNonOwnerSweetLine": _b, "colorOwnerSweetLine": _c, "colorRegularLinks": _d, "colorWhite": _e, "driveItemModal": _f, "formContainer": _10, "previewContainer": _11, "product-block-grid": _12, "product-block-subgrid": _13, "reset-button": _14, "rightContainer": _15, "screenlg": _16, "screenmd": _17, "screenml": _18, "screensm": _19, "screenxlg": _1a, "screenxs": _1b, "screenxxlg": _1c, "screenxxs": _1d, "screenxxxlg": _1e, "single-page-responsive-font-size": _1f, "treeContainer": _20 }
