// extracted by mini-css-extract-plugin
var _1 = "CucJde9Cn17l760zfSCw";
var _2 = "cJEBftjRFrrYBrm7BwuV";
var _3 = "#76F366";
var _4 = "#EB8632";
var _5 = "#ECC834";
var _6 = "#21FC87";
var _7 = "#EA4A30";
var _8 = "#C7EA45";
var _9 = "#272B2B";
var _a = "#BDBDBD";
var _b = "#7D7D7D";
var _c = "#6A0DAD";
var _d = "#3A65FF";
var _e = "#4A90E2";
var _f = "#ffffff";
var _10 = "K3xs3osD4Pays8dKVHCe";
var _11 = "FiNlCkagO0Pn3nAvfIlN";
var _12 = "WR6AOQ7vDwQUNG5jGK4O";
var _13 = "PKWG21BtLifx2ELAWqaF";
var _14 = "bww330SfN38gJ2HOOh_8";
var _15 = "_eBorWhZYZK2SCjr4jSj";
var _16 = "1200px";
var _17 = "992px";
var _18 = "1366px";
var _19 = "768px";
var _1a = "1475px";
var _1b = "576px";
var _1c = "1600px";
var _1d = "320px";
var _1e = "2500px";
var _1f = "fQ_tzZfzS1E3WCJ2to3Q";
var _20 = "_ZkCrSf5GWmw6SJ4XeBM";
export { _1 as "addToCollectionModal", _2 as "base-link", _3 as "colorGradeEX", _4 as "colorGradeFR", _5 as "colorGradeGD", _6 as "colorGradeOU", _7 as "colorGradePR", _8 as "colorGradeVG", _9 as "colorNeutral", _a as "colorNeutralLighest", _b as "colorNeutralLight", _c as "colorNonOwnerSweetLine", _d as "colorOwnerSweetLine", _e as "colorRegularLinks", _f as "colorWhite", _10 as "formContainer", _11 as "previewContainer", _12 as "product-block-grid", _13 as "product-block-subgrid", _14 as "reset-button", _15 as "rightContainer", _16 as "screenlg", _17 as "screenmd", _18 as "screenml", _19 as "screensm", _1a as "screenxlg", _1b as "screenxs", _1c as "screenxxlg", _1d as "screenxxs", _1e as "screenxxxlg", _1f as "single-page-responsive-font-size", _20 as "treeContainer" }
export default { "addToCollectionModal": _1, "base-link": _2, "colorGradeEX": _3, "colorGradeFR": _4, "colorGradeGD": _5, "colorGradeOU": _6, "colorGradePR": _7, "colorGradeVG": _8, "colorNeutral": _9, "colorNeutralLighest": _a, "colorNeutralLight": _b, "colorNonOwnerSweetLine": _c, "colorOwnerSweetLine": _d, "colorRegularLinks": _e, "colorWhite": _f, "formContainer": _10, "previewContainer": _11, "product-block-grid": _12, "product-block-subgrid": _13, "reset-button": _14, "rightContainer": _15, "screenlg": _16, "screenmd": _17, "screenml": _18, "screensm": _19, "screenxlg": _1a, "screenxs": _1b, "screenxxlg": _1c, "screenxxs": _1d, "screenxxxlg": _1e, "single-page-responsive-font-size": _1f, "treeContainer": _20 }
