// extracted by mini-css-extract-plugin
var _1 = "u3zdMQg26Vi31cO0LXst";
var _2 = "pyb2knXFCw2RETUeJWMq";
var _3 = "#76F366";
var _4 = "#EB8632";
var _5 = "#ECC834";
var _6 = "#21FC87";
var _7 = "#EA4A30";
var _8 = "#C7EA45";
var _9 = "#272B2B";
var _a = "#BDBDBD";
var _b = "#7D7D7D";
var _c = "#6A0DAD";
var _d = "#3A65FF";
var _e = "#4A90E2";
var _f = "#ffffff";
var _10 = "pg2vHTqK2tAjReXffdlO";
var _11 = "Qx5yIs0ZZor4QLInrKsU";
var _12 = "yu8MbEncfhnWLNYO3tHI";
var _13 = "GjmE8Ogk3BS4Zhd8TyeY";
var _14 = "s2Q__2EW2fTzP9xuFFhQ";
var _15 = "xPXx8MDP0kXPgUwuwKhO";
var _16 = "N8vIKR_NPp0k2ePzqtEg";
var _17 = "pbNdKpUVhGsgUNawXMS7";
var _18 = "1200px";
var _19 = "992px";
var _1a = "1366px";
var _1b = "768px";
var _1c = "1475px";
var _1d = "576px";
var _1e = "1600px";
var _1f = "320px";
var _20 = "2500px";
var _21 = "rrYZgYtqPRwj74hmOTr3";
export { _1 as "base-link", _2 as "clock", _3 as "colorGradeEX", _4 as "colorGradeFR", _5 as "colorGradeGD", _6 as "colorGradeOU", _7 as "colorGradePR", _8 as "colorGradeVG", _9 as "colorNeutral", _a as "colorNeutralLighest", _b as "colorNeutralLight", _c as "colorNonOwnerSweetLine", _d as "colorOwnerSweetLine", _e as "colorRegularLinks", _f as "colorWhite", _10 as "destination", _11 as "emptyPlaceholder", _12 as "folder", _13 as "forwardDestinationList", _14 as "icon", _15 as "product-block-grid", _16 as "product-block-subgrid", _17 as "reset-button", _18 as "screenlg", _19 as "screenmd", _1a as "screenml", _1b as "screensm", _1c as "screenxlg", _1d as "screenxs", _1e as "screenxxlg", _1f as "screenxxs", _20 as "screenxxxlg", _21 as "single-page-responsive-font-size" }
export default { "base-link": _1, "clock": _2, "colorGradeEX": _3, "colorGradeFR": _4, "colorGradeGD": _5, "colorGradeOU": _6, "colorGradePR": _7, "colorGradeVG": _8, "colorNeutral": _9, "colorNeutralLighest": _a, "colorNeutralLight": _b, "colorNonOwnerSweetLine": _c, "colorOwnerSweetLine": _d, "colorRegularLinks": _e, "colorWhite": _f, "destination": _10, "emptyPlaceholder": _11, "folder": _12, "forwardDestinationList": _13, "icon": _14, "product-block-grid": _15, "product-block-subgrid": _16, "reset-button": _17, "screenlg": _18, "screenmd": _19, "screenml": _1a, "screensm": _1b, "screenxlg": _1c, "screenxs": _1d, "screenxxlg": _1e, "screenxxs": _1f, "screenxxxlg": _20, "single-page-responsive-font-size": _21 }
