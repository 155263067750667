var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { action, observable } from 'mobx';
export default class CutShapePatent {
    country = '';
    patentUrl = '';
    title = '';
    setCountry = (country) => {
        this.country = country;
    };
    setPatentUrl = (patentUrl) => {
        this.patentUrl = patentUrl;
    };
    setTitle = (title) => {
        this.title = title;
    };
    isFilled() {
        return this.country.trim().length > 0 && this.patentUrl.trim().length > 0 && this.title.length > 0;
    }
}
__decorate([
    observable
], CutShapePatent.prototype, "country", void 0);
__decorate([
    observable
], CutShapePatent.prototype, "patentUrl", void 0);
__decorate([
    observable
], CutShapePatent.prototype, "title", void 0);
__decorate([
    action
], CutShapePatent.prototype, "setCountry", void 0);
__decorate([
    action
], CutShapePatent.prototype, "setPatentUrl", void 0);
__decorate([
    action
], CutShapePatent.prototype, "setTitle", void 0);
