import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useCallback, useState } from 'react';
import { generatePath, useLocation } from 'react-router';
import { observer } from 'mobx-react';
import cls from 'classnames';
import { GO_TO_COLLECTIONS_FROM_FOOTER, GO_TO_PRESENTATION_FROM_FOOTER, GO_TO_PRICING_FROM_FOOTER, GO_TO_PRIVACY_FROM_FOOTER, GO_TO_WORKSHOP, } from '@/analytics/services/Analytics';
import { useAnalyticsStore } from '@/analytics/store/AnalyticsStore';
import AboutPopover from '@/common/components/Header/AboutPopover/AboutPopover';
import CollectionNavItem from '@/common/components/Header/CollectionNavItem/CollectionNavItem';
import ComparisonNavItem from '@/common/components/Header/ComparisonNavItem/ComparisonNavItem';
import DiamondsNavItem from '@/common/components/Header/DiamondsNavItem/DiamondsNavItem';
import MobileDrawer from '@/common/components/Header/MobileDrawer/MobileDrawer';
import PinNavItem from '@/common/components/Header/PinNavItem/PinNavItem';
import UserNavDropdown from '@/common/components/Header/UserNavDropdown/UserNavDropdown';
import { COLLECTIONS_PAGE, DRIVE_PAGE, HOME_PAGE, SAME_LAB_PROPORTIONS_DEMO_PAGE, SWEET_LINES_DEMO_PAGE, } from '@/common/constants/pages';
import { DriveSection } from '@/drive/types/driveSection';
import Auth from '@/user/services/auth/Auth';
import Token from '@/user/services/token';
import NetBoxIndicator from '../../../netbox/components/NetBoxIndicator/NetBoxIndicator';
import { useUserStore } from '../../../user/stores/UserStore';
import { SCREEN_MD } from '../../constants/breakpoints';
import { useAppStore } from '../../stores/AppStore';
import Button from '../Button/Button';
import Icon from '../Icon/Icon';
import Link, { NAV, NONE } from '../Link/Link';
import Logo from '../Logo/Logo';
import styles from './Header.less';
function Header(props) {
    const { fixed = true } = props;
    const userStore = useUserStore();
    const appStore = useAppStore();
    const analyticsStore = useAnalyticsStore();
    const location = useLocation();
    const [isAboutPopoverOpened, setIsAboutPopoverOpened] = useState(false);
    const [isMobileMenuOpened, setIsMobileMenuOpened] = useState(false);
    const openMobileMenu = useCallback((e) => {
        e.preventDefault();
        const button = e.target.closest('button');
        if (button) {
            button.blur();
        }
        if (isMobileMenuOpened) {
            return;
        }
        setIsMobileMenuOpened(true);
    }, [isMobileMenuOpened]);
    const closeMobileMenu = useCallback(() => {
        setIsMobileMenuOpened(false);
    }, []);
    const closeAboutPopover = useCallback(() => {
        setIsAboutPopoverOpened(false);
    }, []);
    const toggleAboutPopover = useCallback(() => {
        setIsAboutPopoverOpened((prev) => !prev);
    }, []);
    const handleCollectionsClick = useCallback(() => {
        analyticsStore.sendEventWithContext(GO_TO_COLLECTIONS_FROM_FOOTER);
        closeMobileMenu();
        closeAboutPopover();
    }, [analyticsStore, closeMobileMenu, closeAboutPopover]);
    const handlePresentationClick = useCallback(() => {
        analyticsStore.sendEventWithContext(GO_TO_PRESENTATION_FROM_FOOTER);
        closeMobileMenu();
    }, [analyticsStore, closeMobileMenu]);
    const handleWorkshopClick = useCallback(() => {
        analyticsStore.sendEventWithContext(GO_TO_WORKSHOP);
        closeMobileMenu();
    }, [analyticsStore, closeMobileMenu]);
    const handlePricingClick = useCallback(() => {
        analyticsStore.sendEventWithContext(GO_TO_PRICING_FROM_FOOTER);
        closeMobileMenu();
    }, [analyticsStore, closeMobileMenu]);
    const handlePrivacyPolicyClick = useCallback(() => {
        analyticsStore.sendEventWithContext(GO_TO_PRIVACY_FROM_FOOTER);
        closeMobileMenu();
    }, [analyticsStore, closeMobileMenu]);
    const handleLogout = useCallback(() => {
        try {
            navigator.sendBeacon(Auth.generateLogoutUrl());
        }
        catch (e) {
            // do nothing
        }
        Auth.logout();
    }, []);
    const redirectToLoginPage = useCallback((e) => {
        e.preventDefault();
        Auth.redirectToLoginPage();
    }, []);
    const isHomePage = location.pathname === HOME_PAGE;
    const isDemoCollectionsPageActive = [
        COLLECTIONS_PAGE,
        SWEET_LINES_DEMO_PAGE,
        SAME_LAB_PROPORTIONS_DEMO_PAGE,
    ].includes(location.pathname);
    const isLGDContext = appStore.isLGDContext && !isDemoCollectionsPageActive;
    const isNaturalContext = appStore.isNaturalContext && !isDemoCollectionsPageActive;
    const isJewelryContext = appStore.isJewelryContext && !isDemoCollectionsPageActive;
    const isGemContext = appStore.isGemContext && !isDemoCollectionsPageActive;
    return (_jsx("header", { className: cls(styles.header, { [styles.headerFixed]: fixed }, 'header--displayed'), children: _jsxs("div", { className: styles.headerContent, children: [_jsx("div", { className: "header__hm", children: _jsx(Button, { color: "transparent", onClick: openMobileMenu, withIcon: true, "aria-label": "Header Menu Sections Control for Mobile Devices", children: _jsx(Icon, { name: "hamburger" }) }) }), _jsxs("div", { className: "header__left", children: [_jsx("div", { className: "header__logo", children: _jsx(Link, { to: Token.isAuthorized ? '/catalog/diamond-colorless' : '/', appearance: NONE, children: _jsx(Logo, {}) }) }), _jsx("div", { className: "header__cache-proxy", children: _jsx(NetBoxIndicator, {}) })] }), appStore.screenWidth && appStore.screenWidth < SCREEN_MD && (_jsx(MobileDrawer, { isOpened: isMobileMenuOpened, closeMobileMenu: closeMobileMenu, onCollectionClick: handleCollectionsClick, onPresentationClick: handlePresentationClick, onWorkshopClick: handleWorkshopClick, onPrivacyPolicyClick: handlePrivacyPolicyClick, onPricingClick: handlePricingClick, onLogout: handleLogout, redirectToLoginPage: redirectToLoginPage })), _jsxs("div", { className: "header__chapters", role: "menu", "aria-label": "Header Menu Sections", children: [_jsx(DiamondsNavItem, { isActive: isNaturalContext, title: "Natural Diamonds", colorlessLink: "/catalog/diamond-colorless", fancyLink: "/catalog/diamond-fancy", roughLink: "/catalog/rough" }), _jsx(DiamondsNavItem, { isActive: isLGDContext, title: "Lab-Grown Diamonds", colorlessLink: "/catalog/lgd-colorless", fancyLink: "/catalog/lgd-fancy", roughLink: "/catalog/lgd-rough", navClassName: styles.headerLinkLGD }), _jsx(Link, { to: "/catalog/jewelry", className: cls('header-link', 'header-link--desktop', {
                                [styles.headerLinkActive]: isJewelryContext,
                            }), role: "menuitem", appearance: NONE, children: "Jewelry" }), _jsx(Link, { to: "/catalog/gem", className: cls('header-link', 'header-link--desktop', {
                                [styles.headerLinkActive]: isGemContext,
                            }), role: "menuitem", appearance: NONE, children: "Gems" }), _jsx(Link, { className: cls('header-link', 'header-link--desktop', 'hide-on-small-screens', {
                                [styles.headerLinkActive]: isDemoCollectionsPageActive,
                            }), onClick: handleCollectionsClick, to: "/collections", role: "menuitem", appearance: NONE, children: "Demo" }), _jsx(AboutPopover, { isOpened: isAboutPopoverOpened, onCollectionsClick: handleCollectionsClick, onPresentationClick: handlePresentationClick, onWorkshopClick: handleWorkshopClick, onPricingClick: handlePricingClick, onPrivacyPolicyClick: handlePrivacyPolicyClick, close: closeAboutPopover, children: _jsxs("div", { className: "header-link header-link--desktop header-link--dropdown", onClick: toggleAboutPopover, children: ["About", ' ', _jsx(Icon, { name: "arrow-lt-down", className: cls('header-link__triangle', {
                                            'header-link__triangle--opened': isAboutPopoverOpened,
                                        }) })] }) })] }), _jsxs("div", { className: "header__links", children: [userStore.user?.flags?.allowDrive ? (_jsxs(_Fragment, { children: [_jsx(PinNavItem, {}), _jsx(Link, { appearance: NONE, "aria-label": "Link to collections page", className: "header__link header__link--icon", to: generatePath(DRIVE_PAGE), children: _jsx(Icon, { name: "cutwise-drive" }) }), _jsx(Link, { appearance: NONE, "aria-label": "Link to collections page", className: "header__link header__link--icon", to: generatePath(DRIVE_PAGE, { section: DriveSection.PrivateCollections }), children: _jsx(Icon, { name: "like" }) })] })) : (_jsx(CollectionNavItem, {})), !isHomePage && _jsx(ComparisonNavItem, {}), userStore.isAuthorized ? (_jsx(UserNavDropdown, { user: userStore.user, logout: handleLogout })) : (_jsx(Link, { className: "header__link header__link--desktop", href: "#", onClick: redirectToLoginPage, appearance: NAV, children: "Log in" }))] })] }) }));
}
export default observer(Header);
