import isEmpty from 'lodash/isEmpty';
import isFinite from 'lodash/isFinite';
import isNumber from 'lodash/isNumber';
import { observable, toJS } from 'mobx';
import { dictionaryRepository } from '@/common/repositories/DictionaryRepository';
import { CONTEXT_B2B, CONTEXT_LISTING } from '@/common/constants/context';
import {
  ProductType,
  TYPE_DIAMOND_COLORLESS,
  TYPE_DIAMOND_FANCY, TYPE_JEWELRY,
  TYPE_LGD_COLORLESS,
  TYPE_LGD_FANCY, TYPE_LGD_ROUGH, TYPE_ROUGH
} from '@/product/constants/productTypes';
import {
  PHOTOREAL_PRODUCTS_EXCLUDED,
  PHOTOREAL_PRODUCTS_INCLUDED,
} from '../constants/photorealInclusionFilterValues.ts';
import diff from '../../common/helpers/diff';

// todo b2b, clarity, ... can receive one number
export default class Filters {
  /**
   * @type {?number}
   */
  @observable
  status = null;

  /**
   * @type {number[]}
   */
  @observable
  b2b = [];

  /**
   * @type {?string}
   */
  @observable
  b2bSid = null;

  /**
   * @type {FilterValue}
   */
  @observable
  carat = {
    from: null,
    to: null,
  };

  /**
   * @type {FilterValue}
   */
  @observable
  price = {
    from: null,
    to: null,
  };

  /**
   * @type {number[]}
   */
  @observable
  clarity = [];

  /**
   * @type {number[]}
   */
  @observable
  color = [];

  /**
   * @type {FilterValue}
   * @description Date string in ISO 8601
   */
  @observable
  createdAt = {
    from: null,
    to: null,
  };

  /**
   * @type {number[]}
   */
  @observable
  cutQuality = [];

  /**
   * @type {number[]}
   */
  @observable
  cutShape = [];

  /**
   * @type {number[]}
   */
  @observable
  fluorescenceStrength = [];

  /**
   * @type {number[]}
   */
  @observable
  fluorescenceColor = [];

  /**
   * @type {?boolean}
   */
  @observable
  isFancyColor;

  /**
   * @type {boolean}
   */
  @observable
  isLabGrown;

  /**
   * @type {?number}
   */
  @observable
  photorealProductsInclusion = null;

  /**
   * @type {number[]}
   */
  @observable
  laboratory = [];

  /**
   * @type {string[]}
   */
  @observable
  treatment = [];

  /**
   * @type {string[]}
   */
  @observable
  milky = [];

  /**
   * @type {string[]}
   */
  @observable
  gemType = [];

  /**
   * @type {string[]}
   */
  @observable
  inclusions = [];

  /**
   * @type {number[]}
   */
  @observable
  access = [];

  /**
   * @type {number[]}
   */
  @observable
  polish = [];

  /**
   * @type {number[]}
   */
  @observable
  setupPreset = [];

  /**
   * @type {number[]}
   */
  @observable
  symmetry = [];

  /**
   * @type {number[]}
   */
  @observable
  culetSizeGrade = [];

  /**
   * @type {FilterValue}
   */
  @observable
  girdleThicknessGrade = {
    from: null,
    to: null,
  };

  /**
   * @type {?boolean}
   */
  @observable
  withoutPrice = true;

  /**
   * @type {boolean}
   */
  @observable
  stereo = false;

  /**
   * @type {boolean}
   */
  @observable
  multifocus = false;

  /**
   * @type {number[]}
   */
  @observable
  fancyColorDescHue = [];

  /**
   * @type {number[]}
   */
  @observable
  fancyGrade = [];

  /**
   * @type {FilterValue}
   */
  @observable
  spreadPc = {
    from: null,
    to: null,
  };

  /**
   * @type {FilterValue}
   */
  @observable
  culetPc = {
    from: null,
    to: null,
  };

  /**
   * @type {FilterValue}
   */
  @observable
  tablePc = {
    from: null,
    to: null,
  };

  /**
   * @type {FilterValue}
   */
  @observable
  pavDepthPc = {
    from: null,
    to: null,
  };

  /**
   * @type {FilterValue}
   */
  @observable
  crnHeightPc = {
    from: null,
    to: null,
  };

  /**
   * @type {FilterValue}
   */
  @observable
  heightPc = {
    from: null,
    to: null,
  };

  /**
   * @type {FilterValue}
   */
  @observable
  spreadCt = {
    from: null,
    to: null,
  };

  /**
   * @type {FilterValue}
   */
  @observable
  length = {
    from: null,
    to: null,
  };

  /**
   * @type {FilterValue}
   */
  @observable
  width = {
    from: null,
    to: null,
  };

  /**
   * @type {FilterValue}
   */
  @observable
  rt = {
    from: null,
    to: null,
  };

  /**
   * @type {FilterValue}
   */
  @observable
  height = {
    from: null,
    to: null,
  };

  /**
   * @type {FilterValue}
   */
  @observable
  pavAn = {
    from: null,
    to: null,
  };

  /**
   * @type {FilterValue}
   */
  @observable
  pav1An = {
    from: null,
    to: null,
  };

  /**
   * @type {FilterValue}
   */
  @observable
  crnAn = {
    from: null,
    to: null,
  };

  /**
   * @type {FilterValue}
   */
  @observable
  fire = {
    from: null,
    to: null,
  };

  /**
   * @type {FilterValue}
   */
  @observable
  fireGmc = {
    from: null,
    to: null,
  };

  /**
   * @type {FilterValue}
   */
  @observable
  scintillationGmc = {
    from: null,
    to: null,
  };

  /**
   * @type {FilterValue}
   */
  @observable
  brillianceGmc = {
    from: null,
    to: null,
  };

  /**
   * @type {FilterValue}
   */
  @observable
  brillianceGmc2 = {
    from: null,
    to: null,
  };

  /**
   * @type {FilterValue}
   */
  @observable
  deadzonesVal = {
    from: null,
    to: null,
  };

  /**
   * @type {FilterValue}
   */
  @observable
  symmetryGmc = {
    from: null,
    to: null,
  };

  /**
   * @type {FilterValue}
   */
  @observable
  integralGmc = {
    from: null,
    to: null,
  };

  /**
   * @type {FilterValue}
   */
  @observable
  integralSmc = {
    from: null,
    to: null,
  };

  /**
   * @type {number[]}
   */
  @observable
  fluorGrade = [];

  /**
   * @type {number[]}
   */
  @observable
  fluorColor = [];

  /**
   * @type {FilterValue}
   */
  @observable
  brillianceAvg = {
    from: null,
    to: null,
  };

  /**
   * @type {FilterValue}
   */
  @observable
  brillianceFaceUp = {
    from: null,
    to: null,
  };

  /**
   * @type {?boolean}
   */
  @observable
  withRough = null;

  /**
   * @type {?boolean}
   */
  @observable
  cbr = null;

  /**
   * @type {?boolean}
   */
  @observable
  withDiamonds = null;

  /**
   * @type {?boolean}
   */
  @observable
  withPlotting = null;

  /**
   * @type {?boolean}
   */
  @observable
  withI3D = null;

  setCreatedAt(range) {
    this.createdAt = range;
  }

  /**
   * @param {FilterValue} price
   */
  setPrice(price) {
    this.price = Filters.updateRange(price);
  }

  /**
   * @param {FilterValue} carat
   */
  setCarat(carat) {
    this.carat = Filters.updateRange(carat);
  }

  /**
   * @param {FilterValue} fire
   */
  setFire(fire) {
    this.fire = Filters.updateRange(fire);
  }

  /**
   * @param {FilterValue} filterValue
   */
  setFireGmc(filterValue) {
    this.fireGmc = Filters.updateRange(filterValue);
  }

  /**
   * @param {FilterValue} filterValue
   */
  setScintillationGmc(filterValue) {
    this.scintillationGmc = Filters.updateRange(filterValue);
  }

  /**
   * @param {FilterValue} filterValue
   */
  setBrillianceGmc(filterValue) {
    this.brillianceGmc = Filters.updateRange(filterValue);
  }

  /**
   * @param {FilterValue} filterValue
   */
  setBrillianceGmc2(filterValue) {
    this.brillianceGmc2 = Filters.updateRange(filterValue);
  }

  /**
   * @param {FilterValue} filterValue
   */
  setDeadzonesVal(filterValue) {
    this.deadzonesVal = Filters.updateRange(filterValue);
  }

  /**
   * @param {FilterValue} filterValue
   */
  setSymmetryGmc(filterValue) {
    this.symmetryGmc = Filters.updateRange(filterValue);
  }

  /**
   * @param {FilterValue} integralGmc
   */
  setIntegralGmc(integralGmc) {
    this.integralGmc = Filters.updateRange(integralGmc);
  }

  /**
   * @param {FilterValue} integralSmc
   */
  setIntegralSmc(integralSmc) {
    this.integralSmc = Filters.updateRange(integralSmc);
  }

  /**
   * @param {FilterValue} spreadCt
   */
  setSpreadCt(spreadCt) {
    this.spreadCt = Filters.updateRange(spreadCt);
  }

  /**
   * @param {FilterValue} length
   */
  setLengthMm(length) {
    this.length = Filters.updateRange(length);
  }

  /**
   * @param {FilterValue} width
   */
  setWidthMm(width) {
    this.width = Filters.updateRange(width);
  }

  /**
   * @param {FilterValue} diameterRatio
   */
  setDiameterRatio(diameterRatio) {
    this.rt = Filters.updateRange(diameterRatio);
  }

  /**
   * @param {FilterValue} height
   */
  setTotalHeightMm(height) {
    this.height = Filters.updateRange(height);
  }

  /**
   * @param {FilterValue} pavAn
   */
  setPavilionAngleDeg(pavAn) {
    this.pavAn = Filters.updateRange(pavAn);
  }

  /**
   * @param {FilterValue} pav1An
   */
  setPavilionFirstAngleDeg(pav1An) {
    this.pav1An = Filters.updateRange(pav1An);
  }

  /**
   * @param {FilterValue} crnAn
   */
  setCrownAngleDeg(crnAn) {
    this.crnAn = Filters.updateRange(crnAn);
  }

  /**
   * @param {FilterValue} spreadPc
   */
  setSpreadPc(spreadPc) {
    this.spreadPc = Filters.updateRange(spreadPc);
  }

  /**
   * @param {FilterValue} culetPc
   */
  setCuletPc(culetPc) {
    this.culetPc = Filters.updateRange(culetPc);
  }

  /**
   * @param {FilterValue} tablePc
   */
  setTablePc(tablePc) {
    this.tablePc = Filters.updateRange(tablePc);
  }

  /**
   * @param {FilterValue} pavDepthPc
   */
  setPavilionHeightPcAvg(pavDepthPc) {
    this.pavDepthPc = Filters.updateRange(pavDepthPc);
  }

  /**
   * @param {FilterValue} crnHeightPc
   */
  setCrownHeightPcAvg(crnHeightPc) {
    this.crnHeightPc = Filters.updateRange(crnHeightPc);
  }

  /**
   * @param {FilterValue} heightPc
   */
  setTotalHeightPc(heightPc) {
    this.heightPc = Filters.updateRange(heightPc);
  }

  /**
   * @param {FilterValue} girdleThicknessGrade
   */
  setGirdleThicknessGrade(girdleThicknessGrade) {
    this.girdleThicknessGrade = Filters.updateRange(girdleThicknessGrade);
  }

  /**
   * @param {FilterValue} value
   */
  setBrillianceAvg(value) {
    this.brillianceAvg = Filters.updateRange(value);
  }

  /**
   * @param {FilterValue} value
   */
  setBrillianceFaceUp(value) {
    this.brillianceFaceUp = Filters.updateRange(value);
  }

  /**
   * @param {string} productType
   * @param {?number} context
   * @return {Filters}
   */
  static make(productType, context = CONTEXT_LISTING) {
    const filters = new Filters();

    if (productType === TYPE_DIAMOND_COLORLESS) {
      filters.isFancyColor = false;
      filters.isLabGrown = false;
    }

    if (productType === TYPE_DIAMOND_FANCY) {
      filters.isFancyColor = true;
      filters.isLabGrown = false;
    }

    if (productType === TYPE_LGD_COLORLESS) {
      filters.isFancyColor = false;
      filters.isLabGrown = true;
    }

    if (productType === TYPE_LGD_FANCY) {
      filters.isFancyColor = true;
      filters.isLabGrown = true;
    }

    if (productType === TYPE_LGD_ROUGH) {
      filters.isLabGrown = true;
    }

    if (productType === TYPE_ROUGH) {
      filters.isLabGrown = false;
    }

    if (productType !== ProductType.Gem && productType !== TYPE_JEWELRY && productType !== TYPE_ROUGH && productType !== TYPE_LGD_ROUGH) {
      filters.photorealProductsInclusion = PHOTOREAL_PRODUCTS_EXCLUDED;
    }

    if (context === CONTEXT_B2B) {
      filters.photorealProductsInclusion = PHOTOREAL_PRODUCTS_INCLUDED;
    }

    return filters;
  }

  /**
   * @param {Object} jsonData
   * @param {string} productType
   * @param {?number} context
   * @return {Filters}
   */
  static fromJSON(jsonData, productType, context = CONTEXT_LISTING) {
    const filters = Filters.make(productType, context);
    filters.apply(jsonData);

    return filters;
  }

  static dictionaryValues(dictValues) {
    const res = [];
    dictValues.forEach((v) => {
      res.push(+v);
    });
    return res;
  }

  static dictionaryValuesById(dict, ids) {
    const result = [];

    ids.forEach((id) => {
      const item = dictionaryRepository.findItemInDictionaryById(dict, id);

      if (item) {
        result.push(item);
      }
    });

    return result;
  }
  /**
   * @param {FilterValue} updateObj
   * @return {FilterValue}
   */
  static updateRange(updateObj) {
    const newRange = {
      from: null,
      to: null,
    };

    const from = Number(updateObj.from);
    const to = Number(updateObj.to);

    if (updateObj.from !== null && isNumber(from) && isFinite(from)) {
      newRange.from = from;
    }

    if (updateObj.to !== null && isNumber(to) && isFinite(to)) {
      newRange.to = to;
    }

    return newRange;
  }

  isEqual(object) {
    return isEmpty(this.diff(object));
  }

  diff(otherObject) {
    return diff(toJS(otherObject), toJS(this));
  }

  /**
   * Clone filters for specific product type
   * @param {string} productType
   * @param {?number} context
   * @return {Filters}
   */
  clone(productType, context = CONTEXT_LISTING) {
    const clone = Filters.make(productType, context);
    Object.assign(clone, this);

    return clone;
  }

  /**
   * @param {Object} jsonData
   */
  apply(jsonData) {
    if (jsonData.b2b !== undefined) {
      this.b2b = jsonData.b2b.map(v => Number(v));
    }

    if (jsonData.b2bSid !== undefined) {
      this.b2bSid = jsonData.b2bSid;
    }

    if (jsonData.carat !== undefined) {
      this.setCarat(jsonData.carat);
    }

    if (jsonData.price !== undefined) {
      this.setPrice(jsonData.price);
    }

    if (jsonData.clarity !== undefined) {
      this.clarity = Filters.dictionaryValues(jsonData.clarity);
    }

    if (jsonData.color !== undefined) {
      this.color = Filters.dictionaryValues(jsonData.color);
    }

    if (jsonData.createdAt !== undefined) {
      this.setCreatedAt(jsonData.createdAt);
    }

    if (jsonData.cutQuality !== undefined) {
      this.cutQuality = Filters.dictionaryValues(jsonData.cutQuality);
    }

    if (jsonData.cutShape !== undefined) {
      this.cutShape = Filters.dictionaryValues(jsonData.cutShape);
    }

    if (jsonData.fluorescenceStrength !== undefined) {
      this.fluorescenceStrength = Filters.dictionaryValues(jsonData.fluorescenceStrength);
    }

    if (jsonData.fluorescenceColor !== undefined) {
      this.fluorescenceColor = Filters.dictionaryValues(jsonData.fluorescenceColor);
    }

    if (jsonData.isFancyColor !== undefined) {
      this.isFancyColor = jsonData.isFancyColor;
    }

    if (jsonData.isLabGrown !== undefined) {
      this.isLabGrown = jsonData.isLabGrown;
    }

    if (jsonData.laboratory !== undefined) {
      this.laboratory = Filters.dictionaryValues(jsonData.laboratory);
    }

    if (jsonData.access !== undefined) {
      this.access = Filters.dictionaryValues(jsonData.access);
    }

    if (jsonData.polish !== undefined) {
      this.polish = Filters.dictionaryValues(jsonData.polish);
    }

    if (jsonData.setupPreset !== undefined) {
      this.setupPreset = Filters.dictionaryValues(jsonData.setupPreset);
    }

    if (jsonData.symmetry !== undefined) {
      this.symmetry = Filters.dictionaryValues(jsonData.symmetry);
    }

    if (jsonData.culetSizeGrade !== undefined) {
      this.culetSizeGrade = Filters.dictionaryValues(jsonData.culetSizeGrade);
    }

    if (jsonData.girdleThicknessGrade !== undefined) {
      this.setGirdleThicknessGrade(jsonData.girdleThicknessGrade);
    }

    if (jsonData.withoutPrice !== undefined) {
      this.withoutPrice = jsonData.withoutPrice === true || jsonData.withoutPrice === 'true' || jsonData.withoutPrice === '1';
    }

    if (jsonData.stereo !== undefined) {
      this.stereo = jsonData.stereo === true || jsonData.stereo === 'true' || jsonData.stereo === '1';
    }

    if (jsonData.multifocus !== undefined) {
      this.multifocus = jsonData.multifocus === true || jsonData.multifocus === 'true' || jsonData.multifocus === '1';
    }

    if (jsonData.fancyColorDescHue !== undefined) {
      this.fancyColorDescHue = Filters.dictionaryValues(jsonData.fancyColorDescHue);
    }

    if (jsonData.fancyGrade !== undefined) {
      this.fancyGrade = Filters.dictionaryValues(jsonData.fancyGrade);
    }

    if (jsonData.fire !== undefined) {
      this.setFire(jsonData.fire);
    }

    if (jsonData['fire.gmc'] !== undefined) {
      this.setFireGmc(jsonData['fire.gmc']);
    }

    if (jsonData['scintillation.gmc'] !== undefined) {
      this.setScintillationGmc(jsonData['scintillation.gmc']);
    }

    if (jsonData['brilliance.gmc'] !== undefined) {
      this.setBrillianceGmc(jsonData['brilliance.gmc']);
    }

    if (jsonData['brilliance.gmc2'] !== undefined) {
      this.setBrillianceGmc2(jsonData['brilliance.gmc2']);
    }

    if (jsonData['deadzones.val'] !== undefined) {
      this.setDeadzonesVal(jsonData['deadzones.val']);
    }

    if (jsonData['brilliance.avg'] !== undefined) {
      this.setBrillianceAvg(jsonData['brilliance.avg']);
    }

    if (jsonData['brilliance.faceup'] !== undefined) {
      this.setBrillianceFaceUp(jsonData['brilliance.faceup']);
    }

    if (jsonData['symmetry.gmc'] !== undefined) {
      this.setSymmetryGmc(jsonData['symmetry.gmc']);
    }

    if (jsonData['integral.gmc'] !== undefined) {
      this.setIntegralGmc(jsonData['integral.gmc']);
    }

    if (jsonData['integral.smc'] !== undefined) {
      this.setIntegralSmc(jsonData['integral.smc']);
    }

    if (jsonData.spreadCt !== undefined) {
      this.setSpreadCt(jsonData.spreadCt);
    }

    if (jsonData.length !== undefined) {
      this.setLengthMm(jsonData.length);
    }

    if (jsonData.width !== undefined) {
      this.setWidthMm(jsonData.width);
    }

    if (jsonData.rt !== undefined) {
      this.setDiameterRatio(jsonData.rt);
    }

    if (jsonData.height !== undefined) {
      this.setTotalHeightMm(jsonData.height);
    }

    if (jsonData.pavAn !== undefined) {
      this.setPavilionAngleDeg(jsonData.pavAn);
    }

    if (jsonData.pav1An !== undefined) {
      this.setPavilionFirstAngleDeg(jsonData.pav1An);
    }

    if (jsonData.crnAn !== undefined) {
      this.setCrownAngleDeg(jsonData.crnAn);
    }

    if (jsonData.spreadPc !== undefined) {
      this.setSpreadPc(jsonData.spreadPc);
    }

    if (jsonData.culetPc !== undefined) {
      this.setCuletPc(jsonData.culetPc);
    }

    if (jsonData.tablePc !== undefined) {
      this.setTablePc(jsonData.tablePc);
    }

    if (jsonData.pavDepthPc !== undefined) {
      this.setPavilionHeightPcAvg(jsonData.pavDepthPc);
    }

    if (jsonData.crnHeightPc !== undefined) {
      this.setCrownHeightPcAvg(jsonData.crnHeightPc);
    }

    if (jsonData.heightPc !== undefined) {
      this.setTotalHeightPc(jsonData.heightPc);
    }

    if (jsonData['fluor.grade'] !== undefined) {
      this.fluorGrade = Filters.dictionaryValues(jsonData['fluor.grade']);
    }

    if (jsonData['fluor.color'] !== undefined) {
      this.fluorColor = Filters.dictionaryValues(jsonData['fluor.color']);
    }

    if (jsonData.withRough !== undefined) {
      this.withRough = jsonData.withRough;
    }

    if (jsonData.cbr !== undefined) {
      this.cbr = jsonData.cbr;
    }

    if (jsonData.withDiamonds !== undefined) {
      this.withDiamonds = jsonData.withDiamonds;
    }

    if (jsonData.withPlotting !== undefined) {
      this.withPlotting = jsonData.withPlotting;
    }

    if (jsonData.withI3D !== undefined) {
      this.withI3D = jsonData.withI3D;
    }

    if (jsonData.status !== undefined) {
      this.status = Number(jsonData.status);
    }

    if (jsonData.photorealProductsInclusion !== undefined) {
      this.photorealProductsInclusion = Number(jsonData.photorealProductsInclusion);
    }

    if (jsonData.treatment !== undefined) {
      this.treatment = jsonData.treatment;
    }

    if (jsonData.milky !== undefined) {
      this.milky = jsonData.milky;
    }

    if (jsonData.gemType !== undefined) {
      this.gemType = jsonData.gemType;
    }

    ['openInc', 'whiteInc', 'blackInc'].forEach((key) => {
      if (jsonData[key] && Array.isArray(jsonData[key])) {
        this.inclusions = this.inclusions.concat(
          Filters.dictionaryValuesById(key, jsonData[key]).map(({ title }) => title),
        );
      }
    });
  }
}
