import { DriveItemType } from '@/drive/entities/DriveItem';
export function createEmptyForm() {
    return {
        title: '',
        description: '',
        url: '',
    };
}
export function createFormForDriveItem(item) {
    return {
        ...createEmptyForm(),
        title: item.title ?? '',
        description: item.description ?? '',
        url: item.type === DriveItemType.Url ? item.url : '',
    };
}
export function driveFormToRequestData(form, { isUrl } = { isUrl: false }) {
    const res = {
        title: form.title.trim(),
        description: form.description.trim(),
    };
    if (isUrl) {
        res.url = form.url.trim();
    }
    return res;
}
export function mergeDriveItemWithForm(item, form) {
    const cleanedForm = driveFormToRequestData(form);
    const updatedItem = {
        ...item,
        title: cleanedForm.title,
        description: cleanedForm.description,
    };
    if (updatedItem.type === DriveItemType.Url) {
        updatedItem.url = cleanedForm.url;
    }
    return updatedItem;
}
