// extracted by mini-css-extract-plugin
var _1 = "dnoafIg4v_Ub6yx7itHh";
var _2 = "TuHRc9gKO_sp7eYTHjqQ";
var _3 = "#76F366";
var _4 = "#EB8632";
var _5 = "#ECC834";
var _6 = "#21FC87";
var _7 = "#EA4A30";
var _8 = "#C7EA45";
var _9 = "#272B2B";
var _a = "#BDBDBD";
var _b = "#7D7D7D";
var _c = "#6A0DAD";
var _d = "#3A65FF";
var _e = "#4A90E2";
var _f = "#ffffff";
var _10 = "IBzSHIGzRPyI_avPLKQR";
var _11 = "pk6fbvfF6bv6Os0coeFK";
var _12 = "O9pAW2jFg0RH1X1OSGPb";
var _13 = "UpcEwNNWTMRQEeFV_t78";
var _14 = "1200px";
var _15 = "992px";
var _16 = "1366px";
var _17 = "768px";
var _18 = "1475px";
var _19 = "576px";
var _1a = "1600px";
var _1b = "320px";
var _1c = "2500px";
var _1d = "BZtChCgUupywVv15srL2";
var _1e = "SC8N2URkBMZpX9d9t30n";
var _1f = "a2ZOnPvUNa38OVa1E_kk";
var _20 = "LUrJhAegZjl41dC23KQU";
var _21 = "KmV3o_qDI3rpY8R_AXMA";
export { _1 as "base-link", _2 as "clockIcon", _3 as "colorGradeEX", _4 as "colorGradeFR", _5 as "colorGradeGD", _6 as "colorGradeOU", _7 as "colorGradePR", _8 as "colorGradeVG", _9 as "colorNeutral", _a as "colorNeutralLighest", _b as "colorNeutralLight", _c as "colorNonOwnerSweetLine", _d as "colorOwnerSweetLine", _e as "colorRegularLinks", _f as "colorWhite", _10 as "moreIcon", _11 as "product-block-grid", _12 as "product-block-subgrid", _13 as "reset-button", _14 as "screenlg", _15 as "screenmd", _16 as "screenml", _17 as "screensm", _18 as "screenxlg", _19 as "screenxs", _1a as "screenxxlg", _1b as "screenxxs", _1c as "screenxxxlg", _1d as "single-page-responsive-font-size", _1e as "tab", _1f as "tabs", _20 as "tabsContainer", _21 as "title" }
export default { "base-link": _1, "clockIcon": _2, "colorGradeEX": _3, "colorGradeFR": _4, "colorGradeGD": _5, "colorGradeOU": _6, "colorGradePR": _7, "colorGradeVG": _8, "colorNeutral": _9, "colorNeutralLighest": _a, "colorNeutralLight": _b, "colorNonOwnerSweetLine": _c, "colorOwnerSweetLine": _d, "colorRegularLinks": _e, "colorWhite": _f, "moreIcon": _10, "product-block-grid": _11, "product-block-subgrid": _12, "reset-button": _13, "screenlg": _14, "screenmd": _15, "screenml": _16, "screensm": _17, "screenxlg": _18, "screenxs": _19, "screenxxlg": _1a, "screenxxs": _1b, "screenxxxlg": _1c, "single-page-responsive-font-size": _1d, "tab": _1e, "tabs": _1f, "tabsContainer": _20, "title": _21 }
