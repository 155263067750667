import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback, useEffect, useRef } from 'react';
import cls from 'classnames';
import { useToggle } from '@cutwise/utils';
import styles from './AnimatedDropdown.module.less';
const ANIMATION_DURATION = 300;
export default function AnimatedDropdown(props) {
    const { className, label, children } = props;
    const contentRef = useRef(null);
    const [opened, , close, toggle] = useToggle();
    const [isAnimating, startAnimating, stopAnimating] = useToggle();
    const getContentHeight = useCallback(() => {
        const element = contentRef.current;
        if (!element) {
            return 0;
        }
        const { top } = element.getBoundingClientRect();
        const gapFromBottom = 25; // padding from bottom edge of the screen in pixels
        return window.screen.availHeight - top - gapFromBottom;
    }, []);
    const onClickOutsideHandler = useCallback((e) => {
        e.stopPropagation();
        close();
        startAnimating();
    }, []);
    const onOpenHandler = useCallback((e) => {
        e.stopPropagation();
        toggle();
        startAnimating();
    }, []);
    useEffect(() => {
        if (opened) {
            document.addEventListener('click', onClickOutsideHandler);
        }
        else {
            document.removeEventListener('click', onClickOutsideHandler);
        }
        return () => {
            document.removeEventListener('click', onClickOutsideHandler);
        };
    }, [opened]);
    useEffect(() => {
        let timeout = undefined;
        if (isAnimating) {
            clearTimeout(timeout);
            timeout = setTimeout(() => stopAnimating(), ANIMATION_DURATION);
        }
        return () => {
            clearTimeout(timeout);
        };
    }, [isAnimating]);
    return (_jsxs("div", { className: cls(styles.animatedDropdown, className), children: [_jsxs("div", { className: cls(styles.label, { [styles.opened]: opened }), onClick: onOpenHandler, role: "button", tabIndex: 0, children: [_jsx("div", { className: styles.labelText, children: label }), _jsx("span", { className: cls(styles.triangle, { [styles.opened]: opened }) })] }), _jsx("div", { className: cls(styles.content, { [styles.closed]: !opened, [styles.animating]: isAnimating }), style: {
                    maxHeight: getContentHeight(),
                    '--duration': `${ANIMATION_DURATION}ms`,
                }, children: _jsx("div", { ref: contentRef, className: styles.menuContainer, onClick: close, children: children }) })] }));
}
