import { jsx as _jsx } from "react/jsx-runtime";
import { lazy, Suspense } from 'react';
import { observer } from 'mobx-react';
import Modal from '@/common/components/Modal/Modal';
import TreeItemTitle from '@/common/components/TreeItemModal/TreeItemTitle/TreeItemTitle';
import styles from './QrCodeModal.module.less';
const QrCode = lazy(() => import('@/common/components/QrCode/QrCode'));
function QrCodeModal(props) {
    const { href, onClose, onBack, usePortal } = props;
    return (_jsx(Modal, { isShowed: !!href, onClose: onClose, title: _jsx(TreeItemTitle, { title: "QR Code", onBack: onBack, filterShown: false }), usePortal: usePortal, children: _jsx("div", { className: styles.qrCodeModal, children: _jsx(Suspense, { fallback: null, children: !!href && _jsx(QrCode, { href: href }) }) }) }));
}
export default observer(QrCodeModal);
