import { DriveItemType } from '@/drive/entities/DriveItem';
export var ForwardToFolderAction;
(function (ForwardToFolderAction) {
    ForwardToFolderAction[ForwardToFolderAction["Add"] = 1] = "Add";
    ForwardToFolderAction[ForwardToFolderAction["Move"] = 2] = "Move";
})(ForwardToFolderAction || (ForwardToFolderAction = {}));
export function getUniqueDestinationId(destination) {
    if (destination.type === DriveItemType.Folder) {
        return `${destination.type}:${destination.id}:${destination.action}`;
    }
    return `${destination.type}:${destination.id}`;
}
