import { jsx as _jsx } from "react/jsx-runtime";
import { emptyDecorator } from '@/catalog/constants/emptyDecorator';
import { FieldType } from '@/catalog/constants/fieldTypes';
import { ALL_CONTEXTS } from '@/common/constants/context';
import { FIRE } from '@/media/entity/SetupPreset';
import MetricMap from '@/metric/components/MetricMap/MetricMap';
import Diamond from '@/product/entity/Diamond';
export const FIELD_SCINTILLATION_MAP = {
    key: 'scintillationMap',
    label: 'Scintillation Map',
    fullLabel: 'Scintillation Map',
    type: FieldType.Media,
    spGroupId: null,
    getter(p, viewSettings, maxScale) {
        if (p instanceof Diamond && p.scintillationMetric) {
            return (_jsx(MetricMap, { isSingleScaleEnabled: viewSettings.resultedIsSingleScale, maxScale: maxScale, mediaSize: viewSettings.mediaSize, metric: p.scintillationMetric, product: p, setupPresetId: FIRE }));
        }
        return null;
    },
    getterPlain(p) {
        if (p instanceof Diamond && p.scintillationMetric) {
            return p.scintillationMetric.map;
        }
        return null;
    },
    decorator: emptyDecorator,
    iteratee: null,
    contexts: ALL_CONTEXTS,
};
