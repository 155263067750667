import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { observer } from 'mobx-react';
import cls from 'classnames';
import EmptyPlaceholder from '@/common/components/EmptyPlaceholder/EmptyPlaceholder';
import Icon from '@/common/components/Icon/Icon';
import Loader from '@/common/components/Loader/Loader';
import { useForwardModalStore } from '@/forwarding/components/Forwarder/ForwardModal/ForwardModalStore';
import { getUniqueDestinationId } from '@/forwarding/types/ForwardDestinationListData';
import styles from './ForwardDestinationList.module.less';
function ForwardDestinationList(props) {
    const { onSelect } = props;
    const { destinations, allDestinations } = useForwardModalStore();
    if (!allDestinations || !destinations) {
        return _jsx(Loader, {});
    }
    if (destinations.length === 0 && allDestinations.length === 0) {
        return (_jsxs(EmptyPlaceholder, { className: styles.emptyPlaceholder, children: [_jsx(Icon, { name: "clock", className: styles.clock }), "Your forward history is empty now.", _jsx("br", {}), "Select the forward destination on the right."] }));
    }
    if (destinations.length === 0) {
        return (_jsx(EmptyPlaceholder, { className: styles.emptyPlaceholder, children: "No destinations match your search. Try a different keyword." }));
    }
    return (_jsx("div", { className: styles.forwardDestinationList, children: destinations.map((destination) => (_jsxs("div", { "aria-label": `destination-${destination.id}`, className: cls(styles.destination), onClick: () => onSelect(destination), children: [_jsx(Icon, { name: destination.type === 'folder' ? 'folder' : 'like', className: cls(styles.icon, { [styles.folder]: destination.type === 'folder' }) }), destination.title] }, getUniqueDestinationId(destination)))) }));
}
export default observer(ForwardDestinationList);
