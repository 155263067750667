import { action, computed, observable } from 'mobx';
import ColorMetric from '../../metric/entity/ColorMetric';
import Product from './Product';
import {
  TYPE_DIAMOND_COLORLESS,
  TYPE_DIAMOND_FANCY,
  TYPE_LGD_COLORLESS,
  TYPE_LGD_FANCY,
} from '../constants/productTypes';
import {DMC_REL, HTML_REPORT_REL, I3D_REPORT_REL} from '../../media/entity/Media';
import {I_ASET_BLACK, I_ASET_WHITE} from '../../media/entity/SetupPreset';
import {SHAPE_ROUND} from './CutShape';
import ReportData from './ReportData';
import Rough from './Rough';
import ReportImage from './ReportImage';
import {
  CODE_BRILLIANCE,
  CODE_COLOR,
  CODE_DEAD_ZONES,
  CODE_FIRE,
  CODE_FLUOR,
  CODE_INTEGRAL, CODE_INTEGRAL_NORM,
  CODE_SCINTILLATION, CODE_SPREAD,
  CODE_SYMMETRY,
} from '../../metric/constants/codes';
import FireMetric from '../../metric/entity/FireMetric';
import ScintillationMetric from '../../metric/entity/ScintillationMetric';
import BrightnessMetric from '../../metric/entity/BrightnessMetric';
import FluorMetric from '../../metric/entity/FluorMetric';
import SymmetryMetric from '../../metric/entity/SymmetryMetric';
import IntegralMetric from '../../metric/entity/IntegralMetric';
import SpreadMetric from '../../metric/entity/SpreadMetric';
import {dictionaryRepository} from '../../common/repositories/DictionaryRepository';
import DeadZoneMetric from "@/metric/entity/DeadZoneMetric";

export default class Diamond extends Product {
  @observable
  isFancyColor;

  @observable
  rough;

  @observable
  fancyColorDescModifier;

  @observable
  fancyColorDescHue;

  @observable
  fancyColorDistribution;

  @observable
  color;

  @observable
  clarity;

  @observable
  blackInc = [];

  @observable
  whiteInc = [];

  @observable
  openInc = [];

  @observable
  treatment = [];

  @observable
  milky;

  @observable
  clarityVisibility;

  @observable
  fancyGrade;

  @observable
  cutQuality;

  @observable
  symmetry;

  @observable
  fluorescenceStrength;

  @observable
  fluorescenceColor;

  @observable
  girdleThicknessGrade;

  @observable
  girdleThicknessMax;

  /**
   * @type {?number}
   */
  @observable
  girdleFinish;

  @observable
  culetSizeGrade;

  @observable
  polish;

  /**
   * @type {{blackInc: string[], openInc: string[], treatment: string[], whiteInc: string[]}}
   */
  @observable
  details;

  /**
   * @type {?Plotting[]}
   */
  @observable
  plottings;

  /**
   * @type {?Basis[]}
   */
  @observable
  bases;

  /**
   * @type {string}
   */
  colorGrade;

  /**
   * @type {?[Certification]}
   */
  @observable
  certifications;

  /**
   * @type {FireMetric}
   */
  @observable.ref
  fireMetric;

  /**
   * @type {ScintillationMetric}
   */
  @observable.ref
  scintillationMetric;

  /**
   * @type {BrightnessMetric}
   */
  @observable.ref
  brightnessMetric;

  /**
   * @type {FluorMetric}
   */
  @observable.ref
  fluorMetric;

  /**
   * @type {SymmetryMetric}
   */
  @observable.ref
  symmetryMetric;

  /**
   * @type {IntegralMetric}
   */
  @observable.ref
  integralMetric;

  /**
   * @type {IntegralMetric}
   */
  @observable.ref
  integralNormMetric;

  /**
   * @type {SpreadMetric}
   */
  @observable.ref
  spreadMetric;

  /**
   * @type {ColorMetric}
   */
  @observable.ref
  colorMetric

  /**
   * @type {DeadZoneMetric}
   */
  @observable.ref
  deadZoneMetric

  /**
   * @type {boolean}
   */
  @observable
  isVirtual;

  /**
   * @type {ReportData}
   */
  @observable
  reportData;

  /**
   * @type {string}
   */
  @observable
  i3dReport;

  /**
   * @type {string}
   */
  @observable
  htmlReport;

  /**
   * @type {string}
   */
  @observable
  dmc;

  /**
   * @type {ReportImage[]}
   */
  @observable.ref
  reportImages;

  /**
   * @type {string}
   */
  @observable
  nextAmount;

  /**
   * @type {number}
   */
  @observable
  nextDays;

  constructor(modelData) {
    super(modelData);

    this.populate(modelData);
  }

  /**
   * @override
   * @return {string}
   */
  @computed
  get type() {
    if (this.isFancyColor === true && this.isLabGrown === false) {
      return TYPE_DIAMOND_FANCY;
    }

    if (this.isFancyColor === true && this.isLabGrown === true) {
      return TYPE_LGD_FANCY;
    }

    if (this.isFancyColor === false && this.isLabGrown === false) {
      return TYPE_DIAMOND_COLORLESS;
    }

    if (this.isFancyColor === false && this.isLabGrown === true) {
      return TYPE_LGD_COLORLESS;
    }

    return TYPE_DIAMOND_COLORLESS;
  }


  /**
   * @returns ?number
   * */
  @computed
  get cutShapeId() {
    if (!this.cutShape) {
      return null;
    }

    return this.cutShape.id;
  }

  @computed
  get hasPolishMedia() {
    return Boolean(this.mediaCollection && this.mediaCollection.polishImages && this.mediaCollection.polishImages.length > 0);
  }

  @computed
  get hasReports() {
    return Boolean(this.i3dReportLink || this.i3dComparativeLink || this.dmcModelLink || this.htmlReportLink);
  }

  /**
   * @return {string}
   */
  @computed
  get i3dReportLink() {
    let i3dReportLink = this.i3dReport;
    if (i3dReportLink) {
      return i3dReportLink;
    }

    const i3dReports = this.mediaCollection.i3dReports;
    if (i3dReports && i3dReports.length !== 0) {
      i3dReportLink = i3dReports[0].getLink(I3D_REPORT_REL);
    }

    return i3dReportLink;
  }

  /**
   * @return {?string}
   */
  @computed
  get i3dComparativeLink() {
    let i3dComparativeLink = null;
    const i3dComparativeReports = this.mediaCollection.i3dComparativeReports;
    if (i3dComparativeReports && i3dComparativeReports.length !== 0) {
      i3dComparativeLink = i3dComparativeReports[0].getLink(I3D_REPORT_REL);
    }

    return i3dComparativeLink;
  }

  /**
   * @return {?string}
   */
  @computed
  get dmcModelLink() {
    let dmcModelLink = this.dmc;
    if (dmcModelLink) {
      return dmcModelLink;
    }

    const dmcModels = this.mediaCollection.dmcModels;
    if (dmcModels && dmcModels.length !== 0) {
      dmcModelLink = dmcModels[0].getLink(DMC_REL);
    }

    return dmcModelLink;
  }

  /**
   * @return {string}
   */
  @computed
  get htmlReportLink() {
    let htmlReportLink = this.htmlReport;
    if (htmlReportLink) {
      return htmlReportLink;
    }

    const htmlReports = this.mediaCollection.htmlReports;
    if (htmlReports && htmlReports.length !== 0) {
      htmlReportLink = htmlReports[0].getLink(HTML_REPORT_REL);
    }

    return htmlReportLink;
  }

  @computed
  get hasPlottings() {
    return this.plottings && this.plottings.length > 0;
  }

  @computed
  get correctBases() {
    const hasBases = this.bases && this.bases.length > 0;

    if (!hasBases || !this.hasMedia) {
      return [];
    }

    const spOrder = [I_ASET_WHITE, I_ASET_BLACK];
    const orderedBases = this.orderBasesBySetupPresetIds(spOrder);

    if (!orderedBases || orderedBases.length === 0) {
      return [];
    }

    const firstBasis = orderedBases[0];

    return [firstBasis];
  }

  @computed
  get hasCorrectBases() {
    return this.correctBases && this.correctBases.length > 0;
  }

  @computed
  get isFancyCut() {
    return this.cutShapeId !== SHAPE_ROUND;
  }

  @computed
  get isPatentedCut() {
    return this.cutShape && this.cutShape.cutShapePatents && this.cutShape.cutShapePatents.length > 0;
  }

  @computed
  get certificate() {
    return this.certifications && this.certifications.length > 0 ? this.certifications[0] : null;
  }

  @computed
  get isValid() {
    return this.carat >= 0.01 && this.cutShape;
  }

  @computed
  get isPhotoreal() {
    return this.isVirtual;
  }

  /**
   * @return {boolean}
   */
  @computed
  get hasSpreadData() {
    if (!this.mediaCollection) {
      return false;
    }

    return Boolean(this.mediaCollection.hasSpreadMedia && this.spreadMetric);
  }

  /**
   * @param {?Basis[]} bases
   */
  @action
  setBases(bases) {
    this.bases = bases;
  }

  /**
   * @private
   * @param {number[]} ids
   * @return {Basis[]}
   */
  orderBasesBySetupPresetIds(ids) {
    /**
     * @type {MediaSource[]}
     */
    const mediaSources = this.mediaCollection.mediaItems.map(i => i.mediaSources).flat();
    if (!mediaSources || mediaSources.length === 0) {
      return [];
    }

    return ids
      .map((spId) => {
        return this.bases.find((b) => {
          if (!b.compositeImg || !b.overlayImg) {
            return null;
          }

          const mediaSource = mediaSources.find(ms => ms.id === b.imgMediaSourceId);

          if (!mediaSource) {
            return null;
          }

          const basisSpId = mediaSource.media.setupPreset.id;

          if (!basisSpId || basisSpId !== spId) {
            return null;
          }

          return b;
        });
      })
      .filter(b => Boolean(b));
  }

  /**
   * @private
   * @param key
   * @return {?Object}
   */
  getMetricByKey(key) {
    if (this._embedded && this._embedded.scores && this._embedded.scores[key]) {
      return this._embedded.scores[key];
    }

    return null;
  }

  populate(modelData) {
    if (modelData.rough && modelData.rough.id) {
      modelData.rough = modelData.rough.id;
    }

    super.populate(modelData);

    if (modelData.rough && Number.isInteger(modelData.rough)) {
      this.rough = new Rough({ id: modelData.rough });
    }

    if (this._embedded && this._embedded.reportData && Object.keys(this._embedded.reportData).length !== 0) {
      this.reportData = new ReportData(this._embedded.reportData);
    }

    if (this._embedded && this._embedded.reportImg && this._embedded.reportImg.length !== 0) {
      this.reportImages = this._embedded.reportImg.map(ri => new ReportImage(ri));
    }

    if (this._embedded && this._embedded.i3dReport) {
      this.i3dReport = this._embedded.i3dReport;
    }

    if (this._embedded && this._embedded.htmlReport) {
      this.htmlReport = this._embedded.htmlReport;
    }

    if (this._embedded && this._embedded.dmc) {
      this.dmc = this._embedded.dmc;
    }

    if (this.getMetricByKey(CODE_FIRE)) {
      this.fireMetric = new FireMetric(this.getMetricByKey(CODE_FIRE));
    }

    if (this.getMetricByKey(CODE_SCINTILLATION)) {
      this.scintillationMetric = new ScintillationMetric(this.getMetricByKey(CODE_SCINTILLATION));
    }

    if (this.getMetricByKey(CODE_BRILLIANCE)) {
      this.brightnessMetric = new BrightnessMetric(this.getMetricByKey(CODE_BRILLIANCE));
    }

    if (this.getMetricByKey(CODE_FLUOR)) {
      this.fluorMetric = new FluorMetric(this.getMetricByKey(CODE_FLUOR));
    }

    if (this.getMetricByKey(CODE_SYMMETRY)) {
      this.symmetryMetric = new SymmetryMetric(this.getMetricByKey(CODE_SYMMETRY));
    }

    if (this.getMetricByKey(CODE_INTEGRAL)) {
      this.integralMetric = new IntegralMetric(this.getMetricByKey(CODE_INTEGRAL));
    }

    if (this.getMetricByKey(CODE_INTEGRAL_NORM)) {
      this.integralNormMetric = new IntegralMetric(this.getMetricByKey(CODE_INTEGRAL_NORM));
    }

    if (this.getMetricByKey(CODE_SPREAD)) {
      this.spreadMetric = new SpreadMetric(this.getMetricByKey(CODE_SPREAD));
    }

    if (this.getMetricByKey(CODE_COLOR)) {
      this.colorMetric = new ColorMetric(this.getMetricByKey(CODE_COLOR));
    }

    if (this.getMetricByKey(CODE_DEAD_ZONES)) {
      this.deadZoneMetric = new DeadZoneMetric(this.getMetricByKey(CODE_DEAD_ZONES));
    }

    this.color = Number.isInteger(this.color) ? dictionaryRepository.getColorById(this.color) : this.color;
    this.clarity = Number.isInteger(this.clarity) ? dictionaryRepository.getClarityById(this.clarity) : this.clarity;
    this.cutQuality = Number.isInteger(this.cutQuality) ? dictionaryRepository.getCutQualityById(this.cutQuality) : this.cutQuality;
    this.fancyGrade = Number.isInteger(this.fancyGrade) ? dictionaryRepository.getFancyGradeById(this.fancyGrade) : this.fancyGrade;
    this.fancyColorDescHue = Number.isInteger(this.fancyColorDescHue) ? dictionaryRepository.getFancyColorDescHueById(this.fancyColorDescHue) : this.fancyColorDescHue;
    this.fancyColorDescModifier = Number.isInteger(this.fancyColorDescModifier) ? dictionaryRepository.getColorModifierById(this.fancyColorDescModifier) : this.fancyColorDescModifier;
    this.girdleThicknessGrade = Number.isInteger(this.girdleThicknessGrade) ? dictionaryRepository.getGirdleThicknessGradeById(this.girdleThicknessGrade) : this.girdleThicknessGrade;
    this.girdleThicknessMax = Number.isInteger(this.girdleThicknessMax) ? dictionaryRepository.getGirdleThicknessGradeById(this.girdleThicknessMax) : this.girdleThicknessMax;
    this.fluorescenceColor = Number.isInteger(this.fluorescenceColor) ? dictionaryRepository.getFluorescenceColorById(this.fluorescenceColor) : this.fluorescenceColor;
    this.symmetry = Number.isInteger(this.symmetry) ? dictionaryRepository.getSymmetryById(this.symmetry) : this.symmetry;
    this.polish = Number.isInteger(this.polish) ? dictionaryRepository.getPolishById(this.polish) : this.polish;
    this.culetSizeGrade = Number.isInteger(this.culetSizeGrade) ? dictionaryRepository.getCuletSizeGradeById(this.culetSizeGrade) : this.culetSizeGrade;
    this.fluorescenceStrength = Number.isInteger(this.fluorescenceStrength) ? dictionaryRepository.getFluorescenceStrengthById(this.fluorescenceStrength) : this.fluorescenceStrength;

    if (this.certifications) {
      this.certifications.forEach((c) => {
        c.laboratory = Number.isInteger(c.laboratory) ? dictionaryRepository.getLaboratoryById(c.laboratory) : c.laboratory;
      });
    }

    if (this.details) {
      if (Array.isArray(this.details.blackInc)) {
        this.blackInc = this.details.blackInc;
      }

      if (Array.isArray(this.details.whiteInc)) {
        this.whiteInc = this.details.whiteInc;
      }

      if (Array.isArray(this.details.openInc)) {
        this.openInc = this.details.openInc;
      }

      if (Array.isArray(this.details.treatment)) {
        this.treatment = this.details.treatment;
      }
    }
  }
}
