import { TYPE_ROUGH } from '@/product/constants/productTypes';
import Diamond from '@/product/entity/Diamond';
import Gem from '@/product/entity/Gem';
import Rough from '@/product/entity/Rough';
export function getProductShapeIcon(product) {
    if (product instanceof Diamond || product instanceof Gem) {
        return product.cutShape?.rootShapeId;
    }
    if (product instanceof Rough) {
        return TYPE_ROUGH;
    }
    return product.type;
}
