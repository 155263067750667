import { jsx as _jsx } from "react/jsx-runtime";
import { forwardRef } from 'react';
import ReactQuill, { Quill } from 'react-quill';
import { observer } from 'mobx-react';
import cls from 'classnames';
import AutoLinks from 'quill-auto-links';
import styles from './RichEditor.module.less';
Quill.register('modules/autoLinks', AutoLinks);
export var RichEditorTheme;
(function (RichEditorTheme) {
    RichEditorTheme["Dark"] = "dark";
    RichEditorTheme["White"] = "white";
})(RichEditorTheme || (RichEditorTheme = {}));
function RichEditor(props, ref) {
    const { theme = RichEditorTheme.Dark, hideToolbar, disabled = false, className, ...rest } = props;
    return (_jsx(ReactQuill, { ref: ref, formats: ['bold', 'italic', 'underline', 'strike', 'list', 'blockquote', 'list', 'bullet', 'link'], modules: {
            autoLinks: true,
            toolbar: hideToolbar
                ? null
                : [['bold', 'italic', 'link', 'blockquote'], [{ list: 'ordered' }, { list: 'bullet' }], ['clean']],
            clipboard: {
                // without this option editor add newLine to ul lists.
                // This is a known bug of react-quill - https://github.com/zenoamaro/react-quill/issues/925
                matchVisual: false,
            },
        }, className: cls({ [styles.darkTheme]: theme === RichEditorTheme.Dark }, className), theme: "snow", readOnly: disabled, ...rest }));
}
export default observer(forwardRef(RichEditor));
