import { jsx as _jsx } from "react/jsx-runtime";
import { FieldType } from '@/catalog/constants/fieldTypes';
import { CONTEXT_B2B } from '@/common/constants/context';
import { securityManager, VIEW_PRODUCT_STATUS } from '@/common/services/SecurityManager';
import Diamond from '@/product/entity/Diamond';
import Rough from '@/product/entity/Rough';
import ACLStatus from '@/security/components/ACLStatus/ACLStatus';
export const FIELD_PRIVATE_ACCESS = {
    label: 'Private Access',
    fullLabel: 'Private Access',
    key: 'privateAccess',
    type: FieldType.Scalar,
    spGroupId: null,
    getter(p) {
        const isProductValid = p instanceof Diamond || p instanceof Rough;
        if (!isProductValid) {
            return null;
        }
        return securityManager.isGranted(VIEW_PRODUCT_STATUS, { product: p }) || null;
    },
    getterPlain(p) {
        const isProductValid = p instanceof Diamond || p instanceof Rough;
        if (!isProductValid) {
            return null;
        }
        return securityManager.isGranted(VIEW_PRODUCT_STATUS, { product: p }) || null;
    },
    decorator(_, p) {
        return _jsx(ACLStatus, { isCreatable: true, product: p });
    },
    iteratee: null,
    contexts: [CONTEXT_B2B],
};
