import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useMemo } from 'react';
import { observer } from 'mobx-react';
import Modal from '@/common/components/Modal/Modal';
import DriveItemModalFooter from '@/common/components/TreeItemModal/DriveItemModalFooter/DriveItemModalFooter';
import TreeItemTitle from '@/common/components/TreeItemModal/TreeItemTitle/TreeItemTitle';
import { withStores } from '@/common/hoc/withStores';
import Notifier from '@/common/services/Notifier';
import { useAppStore } from '@/common/stores/AppStore';
import { getModalTitle } from '@/drive/components/DriveItemModal/DriveItemModal.utils';
import DriveItemModalForm from '@/drive/components/DriveItemModal/DriveItemModalForm/DriveItemModalForm';
import DriveItemModalTree from '@/drive/components/DriveItemModal/DriveItemModalTree/DriveItemModalTree';
import DriveItemModalTreeStore, { useDriveItemModalTreeStore, } from '@/drive/components/DriveItemModal/DriveItemModalTree/DriveItemModalTreeStore';
import { getDriveItemId } from '@/drive/components/DriveItemModal/DriveItemModalTree/DriveItemModalTreeStore.utils';
import { useDriveItemModalForm } from '@/drive/components/DriveItemModal/useDriveItemModalForm';
import DriveItemPreview from '@/drive/components/DriveItemPreview/DriveItemPreview';
import DriveItemModalStore, { useDriveItemModalStore } from '@/drive/stores/DriveItemModalStore';
import { createEmptyForm } from '@/drive/types/driveItemModalFormData';
import { isSmScreen } from '@/forwarding/components/Forwarder/ForwardModal/ForwardModal.utils';
import { MediaSize } from '@/media/constants/sizeCodes';
import styles from './DriveItemModal.module.less';
function DriveItemModal(props) {
    const { config, onClose, onSuccess, onBack, usePortal } = props;
    const { forwardConfig, action } = config ?? {};
    const { screenWidth } = useAppStore();
    const driveItemModalStore = useDriveItemModalStore();
    const driveItemModalTreeStore = useDriveItemModalTreeStore();
    useEffect(() => {
        if (forwardConfig) {
            driveItemModalTreeStore.refresh();
        }
        else {
            driveItemModalTreeStore.reset();
        }
    }, [forwardConfig]);
    const initialForm = useMemo(() => (forwardConfig ? forwardConfig.getForm() : createEmptyForm()), [forwardConfig]);
    const { values, errors, handleSubmit, setFieldValue } = useDriveItemModalForm(async (formValues) => {
        if (!forwardConfig || !action) {
            return;
        }
        if (!driveItemModalTreeStore.selectedId) {
            Notifier.warn('Select Folder');
            return;
        }
        if (driveItemModalTreeStore.newItem) {
            await driveItemModalTreeStore.finishCreation();
        }
        const { success } = await driveItemModalStore.submit(forwardConfig, formValues, getDriveItemId(driveItemModalTreeStore.selectedId), action);
        if (success) {
            onClose?.();
            onSuccess?.();
        }
    }, initialForm, forwardConfig?.formDisabled());
    return (_jsxs(Modal, { usePortal: usePortal, isShowed: !!forwardConfig, onClose: onClose, title: _jsx(TreeItemTitle, { title: action ? getModalTitle(action) : '', filterShown: false, onBack: onBack }), renderModalFooter: () => (_jsx(DriveItemModalFooter, { addText: "Add Folder", onAdd: driveItemModalTreeStore.startCreation })), contentClassName: styles.driveItemModal, children: [_jsx("div", { className: styles.treeContainer, children: _jsx(DriveItemModalTree, {}) }), _jsx("div", { className: styles.formContainer, children: _jsxs("div", { className: styles.rightContainer, children: [forwardConfig && (_jsx(DriveItemPreview, { preview: forwardConfig.getPreview(), mediaSize: isSmScreen(screenWidth) ? MediaSize.XSmall : MediaSize.Small })), _jsx(DriveItemModalForm, { values: values, errors: errors, hideLabels: isSmScreen(screenWidth), notePlaceholder: isSmScreen(screenWidth) ? 'Type your note here' : '', disabled: forwardConfig?.formDisabled(), setFieldValue: setFieldValue, onReset: onClose, onSubmit: handleSubmit })] }) })] }));
}
export default withStores(({ driveModifierStore }) => ({
    driveItemModalStore: new DriveItemModalStore(driveModifierStore),
    driveItemModalTreeStore: new DriveItemModalTreeStore(driveModifierStore),
}), observer(DriveItemModal));
