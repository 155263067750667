import { jsx as _jsx } from "react/jsx-runtime";
import { observer } from 'mobx-react';
import cls from 'classnames';
import Link, { REGULAR } from '../../../common/components/Link/Link';
import URLProvider from '../../../common/services/URLProvider';
import styles from './ProductLink.less';
function ProductLink(props) {
    const { product, setupPreset = null, text = null, className, appearance = REGULAR, external = false, ...rest } = props;
    const title = text || product.shortView;
    if (product.isReference) {
        return (_jsx("span", { className: cls(styles.productLink, className), ...rest, title: title, children: title }));
    }
    return (_jsx(Link, { external: external, appearance: appearance, className: cls(styles.productLink, className), to: URLProvider.productDetailPage(product, setupPreset), "aria-label": "Product Link", title: title, ...rest, children: title }));
}
export default observer(ProductLink);
