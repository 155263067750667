import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { observer } from 'mobx-react';
import FancyInput from '@/common/components/inputs/FancyInput/FancyInput';
import Loader from '@/common/components/Loader/Loader';
import TreeItemInput from '@/common/components/ModalTreeItem/TreeItemInput/TreeItemInput';
import { useAddToCollectionModalListStore } from '@/forwarding/components/Forwarder/AddToCollectionModal/AddToCollectionModalList/AddToCollectionModalListStore';
import CollectionListItem from '@/forwarding/components/Forwarder/AddToCollectionModal/AddToCollectionModalList/CollectionListItem/CollectionListItem';
import styles from './AddToCollectionModalList.module.less';
import CollectionList from './CollectionList/CollectionList';
function AddToCollectionModalList() {
    const { allCollections, filteredCollections, newCollectionTitle, isPendingCreation, setSelectedId, setNewCollectionTitle, selectedId, newCollectionId, finishCreation, filter, changeFilter, } = useAddToCollectionModalListStore();
    if (!allCollections || !filteredCollections) {
        return _jsx(Loader, {});
    }
    return (_jsxs("div", { className: styles.container, children: [allCollections.length > 0 && (_jsx("div", { className: styles.inputContainer, children: _jsx(FancyInput, { startIcon: "search", placeholder: "Search", value: filter, onChange: changeFilter }) })), _jsxs("div", { className: styles.list, children: [newCollectionId && (_jsx(CollectionListItem, { title: (_jsx(TreeItemInput, { isPendingCreation: isPendingCreation, isSelected: selectedId === newCollectionId, value: newCollectionTitle, save: finishCreation, onChange: setNewCollectionTitle })), onClick: () => setSelectedId(newCollectionId), isSelected: selectedId === newCollectionId })), _jsx(CollectionList, {})] })] }));
}
export default observer(AddToCollectionModalList);
