import CutwiseAPIClient, { METHOD_DELETE, METHOD_GET, METHOD_PATCH, METHOD_POST, } from '../../common/network/CutwiseAPIClient';
import { DriveItemsResponse } from '../entities/DriveItemsResponse';
import { DriveType } from '../entities/DriveType';
export const PATH_DRIVE = 'api/drive';
export class DriveAPI {
    driveType;
    constructor(driveType = DriveType.CutwiseDrive) {
        this.driveType = driveType;
    }
    async fetchTree() {
        const qb = CutwiseAPIClient.createQueryBuilder()
            .method(METHOD_GET)
            .path(PATH_DRIVE)
            .addQueryStringParam('drive', this.driveType);
        const res = await CutwiseAPIClient.sendQuery(qb.getQuery());
        return res.content ?? [];
    }
    // use null id for drive root content
    async fetchFolder(id) {
        const qb = CutwiseAPIClient.createQueryBuilder()
            .method(METHOD_GET)
            .path(`${PATH_DRIVE}/items`)
            .addQueryStringParam('parent', id)
            .addQueryStringParam('drive', this.driveType);
        const res = await CutwiseAPIClient.sendQuery(qb.getQuery());
        return res.content;
    }
    async batchUpdate(reqData) {
        const qb = CutwiseAPIClient.createQueryBuilder()
            .method(METHOD_PATCH)
            .body(reqData)
            .path(PATH_DRIVE)
            .addQueryStringParam('drive', this.driveType);
        const res = await CutwiseAPIClient.sendQuery(qb.getQuery());
        return new DriveItemsResponse(res.content);
    }
    async batchCreate(reqData) {
        const qb = CutwiseAPIClient.createQueryBuilder()
            .method(METHOD_POST)
            .body(reqData)
            .path(PATH_DRIVE)
            .addQueryStringParam('drive', this.driveType);
        const res = await CutwiseAPIClient.sendQuery(qb.getQuery());
        return new DriveItemsResponse(res.content);
    }
    async deleteById(id) {
        const qb = CutwiseAPIClient.createQueryBuilder()
            .method(METHOD_DELETE)
            .body([{ id }])
            .path(PATH_DRIVE)
            .addQueryStringParam('drive', this.driveType);
        await CutwiseAPIClient.sendQuery(qb.getQuery());
    }
    async makeDto(reqData) {
        const qb = CutwiseAPIClient.createQueryBuilder()
            .method(METHOD_POST)
            .body(reqData)
            .path(`${PATH_DRIVE}/dto`)
            .addQueryStringParam('drive', this.driveType);
        const res = await CutwiseAPIClient.sendQuery(qb.getQuery());
        return new DriveItemsResponse(res.content);
    }
}
