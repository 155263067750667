import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { observer } from 'mobx-react';
import cls from 'classnames';
import Icon from '@/common/components/Icon/Icon';
import FancyInput from '@/common/components/inputs/FancyInput/FancyInput';
import Loader from '@/common/components/Loader/Loader';
import TreeItem from '@/common/components/ModalTreeItem/ModalTreeItem';
import { SortableTree } from '@/common/components/SortableTree/SortableTree';
import EmptyTreePlaceholder from '@/common/components/TreeItemModal/EmptyTreePlaceholder/EmptyTreePlaceholder';
import { useDriveItemModalTreeStore } from '@/drive/components/DriveItemModal/DriveItemModalTree/DriveItemModalTreeStore';
import { DriveSection } from '@/drive/types/driveSection';
import { flattenTree } from '@/drive/utils/DriveTree.utils';
import styles from './DriveItemModalTree.module.less';
function DriveItemModalTree() {
    const { tree, filteredTree, selectedId, setSelectedId, toggleCollapse, isPendingCreation, finishCreation, editTitle, filter, changeFilter, newItem, } = useDriveItemModalTreeStore();
    if (!tree || !filteredTree) {
        return _jsx(Loader, {});
    }
    const mainContent = filteredTree.length === 0 ? (_jsx(EmptyTreePlaceholder, { isTreeFilteredOut: tree.length > 0 })) : (_jsx(SortableTree, { items: filteredTree, selectedId: selectedId, onSelect: (item) => setSelectedId(item.id), onCollapse: toggleCollapse, flattenTree: flattenTree, renderItem: (item) => (_jsx(TreeItem, { isEdited: item.id === newItem?.id, value: item.id === newItem?.id ? newItem.title : item.title, isSelected: item.id === selectedId, onChange: editTitle, save: finishCreation, isPendingCreation: isPendingCreation, icon: item.id === DriveSection.MyDrive ? (_jsx(Icon, { name: "cutwise-drive", className: styles.icon })) : (_jsx(Icon, { name: "folder", className: cls(styles.icon, styles.folder) })) })) }));
    return (_jsxs("div", { className: styles.container, children: [_jsx("div", { className: styles.inputContainer, children: _jsx(FancyInput, { startIcon: "search", placeholder: "Search", value: filter, onChange: changeFilter }) }), _jsx("div", { className: styles.list, children: mainContent })] }));
}
export default observer(DriveItemModalTree);
