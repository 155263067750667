import { jsx as _jsx } from "react/jsx-runtime";
import { FieldType } from '@/catalog/constants/fieldTypes';
import { CONTEXT_B2B } from '@/common/constants/context';
import { securityManager, VIEW_PRODUCT_STATUS } from '@/common/services/SecurityManager';
import Diamond from '@/product/entity/Diamond';
import Rough from '@/product/entity/Rough';
import AccessStatus from '@/security/components/AccessStatus/AccessStatus';
export const FIELD_GLOBAL_ACCESS = {
    label: 'Global Access',
    fullLabel: 'Global Access',
    key: 'globalAccess',
    type: FieldType.Scalar,
    spGroupId: null,
    getter(p) {
        const isProductValid = p instanceof Diamond || p instanceof Rough;
        if (!isProductValid) {
            return null;
        }
        return securityManager.isGranted(VIEW_PRODUCT_STATUS, { product: p }) || null;
    },
    getterPlain(p) {
        const isProductValid = p instanceof Diamond || p instanceof Rough;
        if (!isProductValid) {
            return null;
        }
        return securityManager.isGranted(VIEW_PRODUCT_STATUS, { product: p }) || null;
    },
    decorator(_, p) {
        return _jsx(AccessStatus, { isCreatable: true, product: p });
    },
    iteratee: null,
    contexts: [CONTEXT_B2B],
};
