import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { observer } from 'mobx-react';
import cls from 'classnames';
import FancyInput from '@/common/components/inputs/FancyInput/FancyInput';
import FormGroup from '@/common/components/inputs/FormGroup/FormGroup';
import RequiredMark from '@/common/components/RequiredMark/RequiredMark';
import RichEditor from '@/common/components/RichEditor/RichEditor';
import styles from './DriveItemModalForm.module.less';
function DriveItemModalForm(props) {
    const { values, errors, disabled, isUrlFieldShown, autoFocusField = 'title', notePlaceholder, hideLabels, className, onSubmit, onReset, setFieldValue, } = props;
    return (_jsxs("form", { className: cls(styles.driveItemModalForm, className), id: "drive-modal-form", onSubmit: onSubmit, onReset: onReset, children: [isUrlFieldShown && (_jsxs(FormGroup, { className: styles.formGroup, children: [!hideLabels && (_jsxs("label", { className: styles.label, htmlFor: "url", children: ["URL ", !disabled && _jsx(RequiredMark, {})] })), _jsx(FancyInput, { name: "url", className: cls(disabled && styles.disabledInput), contentClassName: cls(disabled && styles.disabledInputContent), appearance: errors.url && 'error', helperText: errors.url, onChange: (v) => setFieldValue('url', v), value: values.url, disabled: disabled, autoFocus: autoFocusField === 'url' })] })), _jsxs(FormGroup, { className: styles.formGroup, children: [!hideLabels && (_jsxs("label", { className: styles.label, htmlFor: "title", children: ["Title ", !disabled && !isUrlFieldShown && _jsx(RequiredMark, {})] })), _jsx(FancyInput, { name: "title", className: cls(styles.titleInput, disabled && styles.disabledInput), contentClassName: cls(disabled && styles.disabledInputContent), appearance: errors.title && 'error', helperText: errors.title, onChange: (v) => setFieldValue('title', v), value: values.title, autoFocus: autoFocusField === 'title', disabled: disabled })] }), _jsxs(FormGroup, { className: styles.formGroup, children: [!hideLabels && _jsx("label", { className: styles.label, children: "Note" }), _jsx(RichEditor, { onChange: (v) => setFieldValue('description', v), hideToolbar: true, value: values.description, placeholder: notePlaceholder, className: cls(disabled && styles.disabledInput), disabled: disabled })] })] }));
}
export default observer(DriveItemModalForm);
