var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { action, computed, observable } from 'mobx';
import isNil from 'lodash/isNil';
import { sortRepository } from '@/catalog/repository/SortRepository';
import { SCREEN_MD, SCREEN_SM, SCREEN_XS } from '@/common/constants/breakpoints';
import { IS_PORTABLE } from '@/common/helpers/mobileDetect';
import updateWaypoints from '@/common/helpers/updateWaypoints';
import useStore from '@/common/hooks/useStore';
import APIDriver from '@/common/services/session/APIDriver';
import LocalStorageDriver from '@/common/services/session/LocalStorageDriver';
import UserSessionDataManager from '@/common/services/session/UserSessionDataManager';
import Storage, { KEY_IS_SIDEBAR_OPENED } from '@/common/services/Storage';
import Sort from '@/common/state/Sort';
import { configurationStore } from '@/common/stores/ConfigurationStore';
import ProductAPI from '@/product/api/ProductsAPI';
import { ProductType } from '@/product/constants/productTypes';
import ProductRequestHelper from '@/product/repository/ProductRequestHelper';
export default class AppStore {
    /*
     * In application we call "setProductTypeContext" as soon as possible,
     * so "productTypeContext" is always defined
     * */
    productTypeContext;
    isSidebarOpened;
    isHQModeEnabled;
    contentAreaWidth = null;
    screenWidth = null;
    orientation = screen.orientation.type;
    productRequestHelper;
    configurationStore;
    userSessionDataManager;
    constructor(configurationStore) {
        this.configurationStore = configurationStore;
        this.userSessionDataManager = new UserSessionDataManager(new LocalStorageDriver());
        const isSidebarOpened = Storage.getObject(KEY_IS_SIDEBAR_OPENED, Storage.STORAGE_LOCAL);
        this.isSidebarOpened = !isNil(isSidebarOpened) ? isSidebarOpened : !IS_PORTABLE;
        this.isHQModeEnabled = window.devicePixelRatio > 1;
        this.productRequestHelper = new ProductRequestHelper(new ProductAPI());
    }
    get defaultSort() {
        const defaultSort = this.activeSortList[0];
        return new Sort().setSingleSort(defaultSort.field, defaultSort.direction);
    }
    get activeSortList() {
        return sortRepository.getSortsByProductType(this.productTypeContext);
    }
    get isDiamondContext() {
        return [
            ProductType.DiamondColorless,
            ProductType.DiamondFancy,
            ProductType.LGDColorless,
            ProductType.LGDFancy,
        ].includes(this.productTypeContext);
    }
    get isRoughContext() {
        return [ProductType.Rough, ProductType.LGDRough].includes(this.productTypeContext);
    }
    get isJewelryContext() {
        return ProductType.Jewelry === this.productTypeContext;
    }
    get isGemContext() {
        return ProductType.Gem === this.productTypeContext;
    }
    get isNaturalContext() {
        return [ProductType.DiamondColorless, ProductType.DiamondFancy, ProductType.Rough].includes(this.productTypeContext);
    }
    get isLGDContext() {
        return [ProductType.LGDColorless, ProductType.LGDFancy, ProductType.LGDRough].includes(this.productTypeContext);
    }
    get isColorlessContext() {
        return [ProductType.DiamondColorless, ProductType.LGDColorless].includes(this.productTypeContext);
    }
    get isLandscape() {
        return window.innerWidth > window.innerHeight;
    }
    get isPhone() {
        return this.isPhonePortrait || this.isPhoneLandscape;
    }
    // `isPhonePortrait === true` means that user rotated mobile device vertically
    get isPhonePortrait() {
        return this.screenWidth && this.screenWidth <= SCREEN_XS && !this.isLandscape;
    }
    // `isPhoneLandscape === true` means that user rotated mobile device horizontally
    get isPhoneLandscape() {
        return this.screenWidth && this.screenWidth <= SCREEN_MD && this.isLandscape;
    }
    get isTabletPortrait() {
        return this.screenWidth && this.screenWidth >= SCREEN_SM && this.screenWidth <= SCREEN_MD && !this.isLandscape;
    }
    get activeB2BList() {
        let b2bFiltersKey = ProductType.Diamond;
        if (!this.isDiamondContext) {
            b2bFiltersKey = this.productTypeContext;
        }
        if (this.isRoughContext) {
            b2bFiltersKey = ProductType.Rough;
        }
        return this.configurationStore.b2bFilters?.[b2bFiltersKey] ?? [];
    }
    toggleSidebar = () => {
        this.isSidebarOpened = !this.isSidebarOpened;
        Storage.saveObject(KEY_IS_SIDEBAR_OPENED, this.isSidebarOpened, Storage.STORAGE_LOCAL);
        updateWaypoints(); // recalculate visible area of layout
    };
    setProductTypeContext = (context) => {
        this.productTypeContext = context;
    };
    setContainerWidth = (width) => {
        this.contentAreaWidth = width;
    };
    setScreenWidth = (width) => {
        this.screenWidth = width;
    };
    setScreenOrientation = (orientation) => {
        this.orientation = orientation;
    };
    enableCloudSettingsStorage = () => {
        this.userSessionDataManager.setDriver(new APIDriver());
    };
}
__decorate([
    observable
], AppStore.prototype, "productTypeContext", void 0);
__decorate([
    observable
], AppStore.prototype, "isSidebarOpened", void 0);
__decorate([
    observable
], AppStore.prototype, "isHQModeEnabled", void 0);
__decorate([
    observable
], AppStore.prototype, "contentAreaWidth", void 0);
__decorate([
    observable
], AppStore.prototype, "screenWidth", void 0);
__decorate([
    observable
], AppStore.prototype, "orientation", void 0);
__decorate([
    computed
], AppStore.prototype, "defaultSort", null);
__decorate([
    computed
], AppStore.prototype, "activeSortList", null);
__decorate([
    computed
], AppStore.prototype, "isDiamondContext", null);
__decorate([
    computed
], AppStore.prototype, "isRoughContext", null);
__decorate([
    computed
], AppStore.prototype, "isJewelryContext", null);
__decorate([
    computed
], AppStore.prototype, "isGemContext", null);
__decorate([
    computed
], AppStore.prototype, "isNaturalContext", null);
__decorate([
    computed
], AppStore.prototype, "isLGDContext", null);
__decorate([
    computed
], AppStore.prototype, "isColorlessContext", null);
__decorate([
    computed
], AppStore.prototype, "isPhone", null);
__decorate([
    computed
], AppStore.prototype, "isPhonePortrait", null);
__decorate([
    computed
], AppStore.prototype, "isPhoneLandscape", null);
__decorate([
    computed
], AppStore.prototype, "isTabletPortrait", null);
__decorate([
    computed
], AppStore.prototype, "activeB2BList", null);
__decorate([
    action
], AppStore.prototype, "toggleSidebar", void 0);
__decorate([
    action
], AppStore.prototype, "setProductTypeContext", void 0);
__decorate([
    action
], AppStore.prototype, "setContainerWidth", void 0);
__decorate([
    action
], AppStore.prototype, "setScreenWidth", void 0);
__decorate([
    action
], AppStore.prototype, "setScreenOrientation", void 0);
export const useAppStore = () => useStore(AppStore);
export const appStore = new AppStore(configurationStore);
