import { jsx as _jsx } from "react/jsx-runtime";
import { generatePath } from 'react-router';
import { DRIVE_PAGE, SPECTRUM_PAGE } from '@/common/constants/pages';
import Notifier from '@/common/services/Notifier';
import URLProvider from '@/common/services/URLProvider';
import { DriveSection } from '@/drive/types/driveSection';
import ForwardNotification from '../components/Forwarder/ForwardModal/ForwardNotification/ForwardNotification';
export class ForwardNotificationService {
    static addToSpectrumSuccess(itemId) {
        const url = `${SPECTRUM_PAGE}?n=${itemId}`;
        Notifier.success(_jsx(ForwardNotification, { text: "Added to Light-Spectrum Demo", url: url }));
    }
    static addedToDriveSuccess(folderId) {
        Notifier.success(_jsx(ForwardNotification, { text: "Added to Folder", url: generatePath(DRIVE_PAGE, { section: DriveSection.MyDrive, selectedId: folderId ?? undefined }) }));
    }
    static movedToDriveSuccess(folderId) {
        Notifier.success(_jsx(ForwardNotification, { text: "Moved to Folder", url: generatePath(DRIVE_PAGE, { section: DriveSection.MyDrive, selectedId: folderId ?? undefined }) }));
    }
    static addedToCollectionSuccess(collectionId) {
        Notifier.success(_jsx(ForwardNotification, { text: "Added to Collection", url: URLProvider.collectionDetailPage({ id: collectionId }) }));
    }
}
