import { jsx as _jsx } from "react/jsx-runtime";
import { Fragment } from 'react';
import { observer } from 'mobx-react';
import EmptyPlaceholder from '@/common/components/EmptyPlaceholder/EmptyPlaceholder';
import { useAddToCollectionModalListStore } from '@/forwarding/components/Forwarder/AddToCollectionModal/AddToCollectionModalList/AddToCollectionModalListStore';
import CollectionListItem from '@/forwarding/components/Forwarder/AddToCollectionModal/AddToCollectionModalList/CollectionListItem/CollectionListItem';
function CollectionList() {
    const { allCollections, filteredCollections, disabledCollectionIds, setSelectedId, selectedId } = useAddToCollectionModalListStore();
    if (filteredCollections?.length === 0) {
        const text = allCollections?.length === 0
            ? 'No Collections found, please create one'
            : 'No Collections match your search. Try a different keyword';
        return _jsx(EmptyPlaceholder, { children: text });
    }
    return (_jsx(Fragment, { children: filteredCollections?.map((c) => (_jsx(CollectionListItem, { onClick: () => setSelectedId(c.id), isSelected: selectedId === c.id, disabled: disabledCollectionIds.has(c.id), title: c.title }, c.id))) }));
}
export default observer(CollectionList);
