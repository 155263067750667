import { ProductType } from '@/product/constants/productTypes';
import URLBuilder from '../network/URLBuilder';
export const getURLPrefixForProductType = (productType) => {
    switch (productType) {
        case ProductType.Jewelry:
            return 'jewelry';
        case ProductType.Rough:
        case ProductType.LGDRough:
            return 'rough';
        case ProductType.Gem:
            return 'gem';
        default:
            return 'diamond'; // ignore 'diamond-colorless' or 'diamond-fancy', just 'diamond'
    }
};
export default class URLProvider {
    static productEditPage(product) {
        return `/${product.entityType}/edit/${product.id}`;
    }
    static productDetailPage(product = null, setupPreset = null) {
        if (!product || product.isReference) {
            return '';
        }
        if (product.id && product.type) {
            const urlPrefix = getURLPrefixForProductType(product.type);
            let link = `/${urlPrefix}/${product.id}`;
            if (setupPreset) {
                link += `?sp=${setupPreset.id}`;
            }
            if (window.location.search.includes('debug')) {
                link += link.includes('?') ? '&' : '?';
                link += 'debug';
            }
            return link;
        }
        return '';
    }
    static comparisonPage(ids, productType) {
        const productsURIPart = ids ? ids.map((id) => `id[]=${id}`).join('&') : '';
        return `/compare/${productType}${productsURIPart ? `?${productsURIPart}` : ''}`;
    }
    static referenceComparisonPage(productId, productType) {
        return `/compare/reference/${productType}/${productId}`;
    }
    static solutionsPage(project) {
        return `/projects/${project.id}/solutions`;
    }
    static activeSolutionPage(project, solution) {
        if (!project.cbr) {
            return URLProvider.solutionsPage(project);
        }
        const id = solution.guid ? solution.guid : solution.id;
        return `/projects/${project.id}/solutions?activeSolutionId=${id}`;
    }
    static projectsPage() {
        return '/projects';
    }
    static sharedWithMeProjectsPage() {
        return '/shared-with-me-projects';
    }
    static profile(user) {
        if (user.isAnonymous) {
            return '';
        }
        return '/profile';
    }
    static collectionDetailPage(collection) {
        return `/~${collection.id}`;
    }
    static collectionEditPage(collection) {
        return `/~${collection.id}/edit`;
    }
    static customGradingCollectionPage(collectionId, customGradingCode) {
        return `/grading/custom/${customGradingCode}?c=${collectionId}`;
    }
    static backOfficePage(productType) {
        return `/catalog/${productType}/edit`;
    }
    static productQcPage(productId1, productId2) {
        return URLBuilder.buildUrl('/api/internal/qc', { url1: productId1, url2: productId2 });
    }
    static adminPage() {
        return URLBuilder.buildUrl('/', {}, 'admin');
    }
    static convertRelativeToAbsolute(url) {
        return `${window.location.origin}${url}`;
    }
    static convertToRelativeLink(absoluteLink) {
        return new URL(absoluteLink).pathname;
    }
}
