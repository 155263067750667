var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { computed, observable } from 'mobx';
import { isDiamond } from '@/product/constants/productTypes';
import SetupPreset, { I_MUNSELL, I_MUNSELL_CLOSEUP, NON_DIAMOND_SETUP_PRESETS, } from '../entity/SetupPreset';
export const BACKGROUND_COLOR_DEFAULT = '#000000';
export const BACKGROUND_COLOR_WHITE = '#ffffff';
export const BACKGROUND_COLOR_AUTO = 'auto';
export default class SetupPresetRepository {
    setupPresets = [];
    setupPresetOrder = [];
    get nonDiamondSetupPresets() {
        const setupSet = new Set(NON_DIAMOND_SETUP_PRESETS);
        return this.setupPresets.filter((sp) => setupSet.has(sp.id));
    }
    get defaultSetupPreset() {
        if (!this.setupPresets || this.setupPresets.length === 0) {
            return null;
        }
        const spOrder = this.getSetupPresetOrder();
        for (const spId of spOrder) {
            const sp = this.findSetupPresetById(spId);
            if (sp) {
                return sp;
            }
        }
        return null;
    }
    initSetupPresets(setupPresets) {
        this.setupPresets = setupPresets.map((setupPresetsItem) => new SetupPreset(setupPresetsItem, true));
    }
    initSetupPresetOrder(setupPresetOrderData) {
        this.setupPresetOrder = setupPresetOrderData;
    }
    findSetupPresetById(id) {
        if (!this.setupPresets || this.setupPresets.length === 0) {
            return null;
        }
        return this.setupPresets.find((sp) => sp.id === id) ?? null;
    }
    findSetupPresetBackground(setupPreset) {
        if (!setupPreset) {
            return BACKGROUND_COLOR_DEFAULT;
        }
        if (setupPreset.id === I_MUNSELL || setupPreset.id === I_MUNSELL_CLOSEUP) {
            return BACKGROUND_COLOR_WHITE;
        }
        const bgColor = setupPreset.bg;
        if (!bgColor) {
            return BACKGROUND_COLOR_DEFAULT;
        }
        if (bgColor === BACKGROUND_COLOR_AUTO) {
            return null;
        }
        return bgColor;
    }
    getSetupPresetOrder() {
        if (!this.setupPresetOrder) {
            return [];
        }
        return this.setupPresetOrder;
    }
    getSetupPresetsByProductType(productType) {
        return isDiamond(productType) ? this.setupPresets : this.nonDiamondSetupPresets;
    }
}
__decorate([
    observable
], SetupPresetRepository.prototype, "setupPresets", void 0);
__decorate([
    observable
], SetupPresetRepository.prototype, "setupPresetOrder", void 0);
__decorate([
    computed
], SetupPresetRepository.prototype, "nonDiamondSetupPresets", null);
__decorate([
    computed
], SetupPresetRepository.prototype, "defaultSetupPreset", null);
export const setupPresetRepository = new SetupPresetRepository();
