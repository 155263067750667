import { jsx as _jsx } from "react/jsx-runtime";
import { memo } from 'react';
import cls from 'classnames';
import { logger } from '@cutwise/utils';
import { iconNames } from './iconName';
const iconMap = new Map(iconNames.map((name) => [name, require(`@/assets/icons/${name}.svg`).default]));
function Icon(props) {
    const { className, name, ...rest } = props;
    const IconComponent = iconMap.get(name);
    if (!IconComponent) {
        logger.error(`Icon with name ${name} not found`);
        return null;
    }
    return _jsx(IconComponent, { className: cls('cw-icon', className), ...rest });
}
export default memo(Icon);
