import URLBuilder from '@/common/network/URLBuilder';
import Gem from '@/product/entity/Gem';
import token from '@/user/services/token';
import CutwiseAPIClient, { METHOD_DELETE, METHOD_GET, METHOD_PATCH } from '../../common/network/CutwiseAPIClient';
import ConsoleLog from '../../common/network/middleware/ConsoleLog';
import FiltersJSONSerializer from '../../common/serializers/filters/FiltersJSONSerializer';
import Sort from '../../common/state/Sort';
import { SECURITY_CHECK_ACL } from '../../security/constants/securityCheck';
import { ProductType, TYPE_JEWELRY, TYPE_LGD_ROUGH, TYPE_ROUGH } from '../constants/productTypes';
import Diamond from '../entity/Diamond';
import Jewelry from '../entity/Jewelry';
import Rough from '../entity/Rough';
import TransformB2bMiddleware from './middleware/TransformB2bMiddleware';
import TransformB2bSidMiddleware from './middleware/TransformB2bSidMiddleware';
import TransformCaratWeightMiddleware from './middleware/TransformCaratWeightMiddleware';
import TransformCutShapeList from './middleware/TransformCutShapeList';
import TransformDatesMiddleware from './middleware/TransformDatesMiddleware';
import TransformOriginalCaratToCarat from './middleware/TransformOriginalCaratToCarat';
import TransformPhotorealProductsInclusion from './middleware/TransformPhotorealProductsInclusion';
export const PATH_DIAMOND = 'api/v2/diamonds';
export const PATH_ROUGH = 'api/v2/roughs';
export const PATH_JEWELRY = 'api/v2/jewelries';
export const PATH_GEM = 'api/v2/gems';
export const PATH_PDF_REPORT = 'pdf/render/pdf';
const entityConstructors = {
    [PATH_DIAMOND]: Diamond,
    [PATH_ROUGH]: Rough,
    [PATH_JEWELRY]: Jewelry,
    [PATH_GEM]: Gem,
};
export default class ProductAPI {
    path;
    b2bContext;
    constructor(path = PATH_DIAMOND, b2bContext) {
        this.path = path;
        this.b2bContext = b2bContext;
        this.path = path;
        this.b2bContext = b2bContext;
    }
    get entityConstructor() {
        return entityConstructors[this.path];
    }
    fetchItem(id, shouldDumpResponse) {
        const EntityConstructor = this.entityConstructor;
        const qb = CutwiseAPIClient.createQueryBuilder()
            .method(METHOD_GET)
            .path(`${this.path}/${id}`)
            .hydration((data) => new EntityConstructor(data))
            .suppressErrorNotifications();
        if (shouldDumpResponse) {
            qb.useMiddlewareOnResponse(ConsoleLog);
        }
        return CutwiseAPIClient.sendQuery(qb.getQuery());
    }
    patch(id, data) {
        const qb = CutwiseAPIClient.createQueryBuilder()
            .method(METHOD_PATCH)
            .path(`${this.path}/${id}`)
            .body(data)
            .useMiddlewareOnRequest(TransformOriginalCaratToCarat);
        return CutwiseAPIClient.sendQuery(qb.getQuery());
    }
    delete(id) {
        const qb = CutwiseAPIClient.createQueryBuilder().method(METHOD_DELETE).path(`${this.path}/${id}`);
        return CutwiseAPIClient.sendQuery(qb.getQuery());
    }
    fetchOwned(isLabGrown = false) {
        const qb = CutwiseAPIClient.createQueryBuilder()
            .method(METHOD_GET)
            .path(this.path)
            .addQueryStringParam('filters', { b2b: token.b2bId, isLabGrown })
            .shortResponseData()
            .limit(0);
        return CutwiseAPIClient.sendQuery(qb.getQuery());
    }
    fetchProductsByFilters(filters = null, sort = null, offset = null, limit = null, indexOnly = false, securityCheck = SECURITY_CHECK_ACL) {
        const filtersJSON = filters ? FiltersJSONSerializer.serialize(filters) : null;
        const qb = CutwiseAPIClient.createQueryBuilder()
            .method(METHOD_GET)
            .path(this.path)
            .useMiddlewareOnRequest(TransformDatesMiddleware)
            .useMiddlewareOnRequest(TransformB2bSidMiddleware)
            .useMiddlewareOnRequest(TransformCaratWeightMiddleware)
            .useMiddlewareOnRequest(TransformPhotorealProductsInclusion)
            .useMiddlewareOnRequest(TransformCutShapeList)
            .addQueryStringParam('filters', filtersJSON)
            .addQueryStringParam('order_by', sort ? sort.transform() : null)
            .offset(offset)
            .limit(limit)
            .securityCheck(securityCheck);
        if (this.b2bContext) {
            qb.useMiddlewareOnRequest(TransformB2bMiddleware(this.b2bContext));
        }
        if (indexOnly) {
            qb.indexResponseData();
        }
        return CutwiseAPIClient.sendQuery(qb.getQuery());
    }
    async fetchProductsById(idList, isLabGrown = null, sort = null, indexOnly = false, scope = null) {
        const correctedSort = sort && sort.getFirstField() === Sort.SORT_MANUAL ? null : sort;
        const qb = CutwiseAPIClient.createQueryBuilder()
            .method(METHOD_GET)
            .path(this.path)
            .addQueryStringParam('filters', { id: idList, isLabGrown })
            .addQueryStringParam('order_by', correctedSort ? correctedSort.transform() : null)
            .addQueryStringParam('scope', scope)
            .limit(1000);
        if (indexOnly) {
            qb.indexResponseData();
        }
        const EntityConstructor = this.entityConstructor;
        const res = await CutwiseAPIClient.sendQuery(qb.getQuery());
        return indexOnly ? res : res.content?.map((d) => new EntityConstructor(d)) || null;
    }
    updatePath(productType) {
        if (productType === TYPE_ROUGH || productType === TYPE_LGD_ROUGH) {
            this.path = PATH_ROUGH;
        }
        else if (productType === TYPE_JEWELRY) {
            this.path = PATH_JEWELRY;
        }
        else if (productType === ProductType.Gem) {
            this.path = PATH_GEM;
        }
        else {
            this.path = PATH_DIAMOND;
        }
    }
    fetchPDFReport(productId) {
        const qb = CutwiseAPIClient.createQueryBuilder()
            .base(window.location.origin)
            .method(METHOD_GET)
            .path(PATH_PDF_REPORT)
            .asBlob()
            .addQueryStringParam('body', JSON.stringify({
            url: URLBuilder.toAbsoluteUrl(`/api/print/diamond/${productId}?access_token=${token.accessToken}`),
            options: {
                format: 'A4',
                landscape: true,
            },
            waitFor: 2000,
        }));
        return CutwiseAPIClient.sendQuery(qb.getQuery());
    }
    static make(productType, b2bContext) {
        if (productType === TYPE_ROUGH || productType === TYPE_LGD_ROUGH) {
            return new ProductAPI(PATH_ROUGH, b2bContext);
        }
        if (productType === TYPE_JEWELRY) {
            return new ProductAPI(PATH_JEWELRY, b2bContext);
        }
        if (productType === ProductType.Gem) {
            return new ProductAPI(PATH_GEM, b2bContext);
        }
        return new ProductAPI(PATH_DIAMOND, b2bContext);
    }
}
