import {
  TYPE_DIAMOND_FANCY, TYPE_JEWELRY, TYPE_LGD_ROUGH, TYPE_ROUGH, TYPE_GEM,
} from '../constants/productTypes';
import Rough from './Rough';
import Jewelry from './Jewelry';
import Diamond from './Diamond';
import Gem from './Gem';

export default class ProductFactory {
  /**
   * @param {string} productType
   * @param {Object} productData
   * @return {Diamond|Rough|Jewelry|Gem}
   */
  static make(productType, productData) {
    if (productType === TYPE_ROUGH || productType === TYPE_LGD_ROUGH) {
      return new Rough(productData);
    }
    if (productType === TYPE_JEWELRY) {
      return new Jewelry(productData);
    }
    if (productType === TYPE_GEM) {
      return new Gem(productData);
    }

    const diamond = new Diamond(productData);
    if (productType === TYPE_DIAMOND_FANCY) {
      diamond.isFancyColor = true;
    }

    return diamond;
  }
}
