var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { observable } from 'mobx';
import { Context } from '@/common/constants/context';
import { setupPresetGroupRepository } from '@/media/repository/SetupPresetGroupRepository';
import { setupPresetRepository } from '@/media/repository/SetupPresetRepository';
import { ProductType } from '@/product/constants/productTypes';
import { DefaultViewTemplate } from '../constants/defaultViewTemplates';
import ViewTemplate from '../entity/ViewTemplate';
class ViewTemplateRepository {
    viewTemplates = [];
    /*
     * Typing here is totally broken
     *
     * Value "viewTemplatesData" goes from "@/assets/data/settingsPreset.json",
     * need to review this file, understand its goal and update types
     * */
    initViewTemplates = (viewTemplatesData) => {
        this.viewTemplates = viewTemplatesData.map((d) => new ViewTemplate(d, setupPresetRepository.findSetupPresetById(d.setupPreset), setupPresetGroupRepository.findSetupPresetGroupById(d.setupPresetGroup)));
    };
    findViewTemplateById(id) {
        if (!this.viewTemplates || this.viewTemplates.length === 0) {
            return null;
        }
        return this.viewTemplates?.find((sp) => sp.id === id) ?? null;
    }
    findViewTemplates(productType, context = null) {
        const spType = ViewTemplate.getTypeByProductType(productType);
        const viewTemplatesFilteredByProductType = this.viewTemplates.filter((vt) => vt.type.includes(spType));
        if (!context) {
            return viewTemplatesFilteredByProductType;
        }
        return viewTemplatesFilteredByProductType.filter((vt) => vt.context.includes(context));
    }
    getDefaultViewTemplateId(productType, context = Context.Listing) {
        if (productType === ProductType.Rough || productType === ProductType.LGDRough) {
            if (context === Context.B2B) {
                return DefaultViewTemplate.RoughEdit;
            }
            return DefaultViewTemplate.Rough;
        }
        if (productType === ProductType.Jewelry || productType === ProductType.Gem) {
            if (context === Context.B2B) {
                return DefaultViewTemplate.JewelryEdit;
            }
            return DefaultViewTemplate.Jewelry;
        }
        if (productType === ProductType.DiamondFancy) {
            if (context === Context.B2B) {
                return DefaultViewTemplate.FancyEdit;
            }
            return DefaultViewTemplate.Fancy;
        }
        if (context === Context.B2B) {
            return DefaultViewTemplate.ColorlessEdit;
        }
        return DefaultViewTemplate.Colorless;
    }
}
__decorate([
    observable
], ViewTemplateRepository.prototype, "viewTemplates", void 0);
export const viewTemplateRepository = new ViewTemplateRepository();
