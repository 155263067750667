var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { action, observable } from 'mobx';
import useStore from '@/common/hooks/useStore';
import Notifier from '@/common/services/Notifier';
import { DriveItemType } from '@/drive/entities/DriveItem';
import ForwardDestinationStorage from '@/forwarding/services/ForwardDestinationStorage';
import { ForwardNotificationService } from '@/forwarding/services/ForwardNotificationService';
import { ForwardToFolderAction } from '@/forwarding/types/ForwardDestinationListData';
class DriveItemModalStore {
    driveModifierStore;
    isSubmitting = false;
    constructor(driveModifierStore) {
        this.driveModifierStore = driveModifierStore;
    }
    submit = async (config, form, parent, action) => {
        try {
            this.isSubmitting = true;
            if (parent) {
                ForwardDestinationStorage.add({ id: parent, type: DriveItemType.Folder, action });
            }
            if (action === ForwardToFolderAction.Add) {
                return this.add(config, form, parent);
            }
            else {
                return this.move(config, form, parent);
            }
        }
        finally {
            this.isSubmitting = false;
        }
    };
    async add(config, form, parent) {
        const res = await this.driveModifierStore.addBatch(config, form, parent);
        if (res.hasErrors()) {
            res.getErrors().forEach((error) => Notifier.error(error));
            return { success: false };
        }
        else {
            ForwardNotificationService.addedToDriveSuccess(parent);
            return { success: true };
        }
    }
    async move(config, form, parent) {
        const res = await this.driveModifierStore.moveBatch(config, form, parent);
        if (res.hasErrors()) {
            res.getErrors().forEach((error) => Notifier.error(error));
            return { success: false };
        }
        else {
            ForwardNotificationService.movedToDriveSuccess(parent);
            return { success: true };
        }
    }
}
__decorate([
    observable
], DriveItemModalStore.prototype, "isSubmitting", void 0);
__decorate([
    action
], DriveItemModalStore.prototype, "submit", void 0);
export const useDriveItemModalStore = () => useStore(DriveItemModalStore);
export default DriveItemModalStore;
