var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { action, computed, observable } from 'mobx';
import { v4 } from 'uuid';
import { filterTreeDeep } from '@/common/components/SortableTree/SortableTree.utils';
import { findItemDeep, setProperty } from '@/common/helpers/tree.utils';
import useStore from '@/common/hooks/useStore';
import Notifier from '@/common/services/Notifier';
import { DriveAPI } from '@/drive/api/DriveAPI';
import { getDriveItemId } from '@/drive/components/DriveItemModal/DriveItemModalTree/DriveItemModalTreeStore.utils';
import { createMyDriveSection } from '@/drive/constants/sidebarTreeSections';
import { DriveItemType } from '@/drive/entities/DriveItem';
import { createEmptyForm } from '@/drive/types/driveItemModalFormData';
import { DriveSection } from '@/drive/types/driveSection';
import { fromDriveFolder } from '@/drive/types/driveTreeItem';
class DriveItemModalTreeStore {
    driveModifierStore;
    driveAPI;
    filter = '';
    tree = null;
    get filteredTree() {
        if (!this.tree) {
            return null;
        }
        const filterText = this.filter.toLowerCase().trim();
        if (filterText.length === 0) {
            return this.tree;
        }
        return filterTreeDeep(this.tree, (item) => item.title.toLowerCase().includes(filterText));
    }
    selectedId = null;
    get selected() {
        return this.tree && this.selectedId ? findItemDeep(this.tree, this.selectedId) : null;
    }
    newItemParentId = null;
    newItem = null;
    isPendingCreation = false;
    constructor(driveModifierStore, driveAPI = new DriveAPI()) {
        this.driveModifierStore = driveModifierStore;
        this.driveAPI = driveAPI;
    }
    refresh = async () => {
        const driveRoot = createMyDriveSection();
        const folders = await this.driveAPI.fetchTree();
        driveRoot.collapsed = false;
        driveRoot.children = folders.map(fromDriveFolder);
        this.tree = [driveRoot];
    };
    reset = () => {
        this.tree = null;
        this.selectedId = null;
    };
    toggleCollapse = (item) => {
        this.tree = setProperty(this.filteredTree, item.id, 'collapsed', (value) => !value);
    };
    setSelectedId = (id) => {
        this.selectedId = id;
    };
    changeFilter = (filter) => {
        this.filter = filter;
    };
    editTitle = (title) => {
        if (!this.newItem || this.isPendingCreation) {
            return;
        }
        this.newItem = { ...this.newItem, title };
    };
    startCreation = async () => {
        if (this.isPendingCreation) {
            return;
        }
        if (this.newItem) {
            await this.finishCreation();
        }
        if (!this.tree) {
            // tree is null because user closed modal
            return;
        }
        this.newItem = {
            id: v4(),
            title: 'New Folder',
            collapsed: true,
            type: DriveItemType.Folder,
        };
        this.newItemParentId = this.selectedId || DriveSection.MyDrive;
        const parent = findItemDeep(this.tree, this.newItemParentId);
        parent.collapsed = false;
        if (!parent.children) {
            parent.children = [];
        }
        parent.children.unshift(this.newItem);
        this.selectedId = this.newItem.id;
        this.tree = [...this.tree];
    };
    finishCreation = async () => {
        if (!this.tree || !this.newItem) {
            throw new Error('finishCreation called when tree or newItem is null');
        }
        this.isPendingCreation = true;
        try {
            const parentId = this.newItemParentId;
            const local = this.newItem;
            const remote = await this.driveModifierStore.create({ ...createEmptyForm(), title: local.title }, DriveItemType.Folder, getDriveItemId(parentId));
            if (remote.hasErrors()) {
                remote.getErrors().forEach((error) => Notifier.error(error));
                return;
            }
            this.resolveOptimisticUpdate(local, fromDriveFolder({ ...remote.getItems()[0], children: [] }), parentId);
        }
        finally {
            this.isPendingCreation = false;
        }
    };
    // separate update logic from driveModifierStore is used because localItem added before call to backend
    resolveOptimisticUpdate(local, remote, parentId) {
        if (!this.tree) {
            return null;
        }
        if (this.selectedId === local.id) {
            this.selectedId = remote.id;
        }
        this.newItem = null;
        this.newItemParentId = null;
        const parent = findItemDeep(this.tree, parentId);
        parent.children = parent.children?.map((node) => (node.id === local.id ? remote : node));
        this.tree = [...this.tree];
        return remote;
    }
}
__decorate([
    observable
], DriveItemModalTreeStore.prototype, "filter", void 0);
__decorate([
    observable
], DriveItemModalTreeStore.prototype, "tree", void 0);
__decorate([
    computed
], DriveItemModalTreeStore.prototype, "filteredTree", null);
__decorate([
    observable
], DriveItemModalTreeStore.prototype, "selectedId", void 0);
__decorate([
    computed
], DriveItemModalTreeStore.prototype, "selected", null);
__decorate([
    observable
], DriveItemModalTreeStore.prototype, "newItemParentId", void 0);
__decorate([
    observable
], DriveItemModalTreeStore.prototype, "newItem", void 0);
__decorate([
    observable
], DriveItemModalTreeStore.prototype, "isPendingCreation", void 0);
__decorate([
    action
], DriveItemModalTreeStore.prototype, "refresh", void 0);
__decorate([
    action
], DriveItemModalTreeStore.prototype, "reset", void 0);
__decorate([
    action
], DriveItemModalTreeStore.prototype, "toggleCollapse", void 0);
__decorate([
    action
], DriveItemModalTreeStore.prototype, "setSelectedId", void 0);
__decorate([
    action
], DriveItemModalTreeStore.prototype, "changeFilter", void 0);
__decorate([
    action
], DriveItemModalTreeStore.prototype, "editTitle", void 0);
__decorate([
    action
], DriveItemModalTreeStore.prototype, "startCreation", void 0);
__decorate([
    action
], DriveItemModalTreeStore.prototype, "finishCreation", void 0);
export const useDriveItemModalTreeStore = () => useStore(DriveItemModalTreeStore);
export default DriveItemModalTreeStore;
