import { DriveItemType } from '@/drive/entities/DriveItem';
import { productTypeToBaseProductType } from '@/product/constants/productTypes';
export var PinnedItemType;
(function (PinnedItemType) {
    PinnedItemType["CarbonProject"] = "carbonProject";
    PinnedItemType["Product"] = "product";
    PinnedItemType["DriveItem"] = "driveItem";
})(PinnedItemType || (PinnedItemType = {}));
export function createPinnedProduct(product) {
    return {
        id: createPinnedItemId(product.id, PinnedItemType.Product),
        productId: product.id,
        type: PinnedItemType.Product,
        productType: productTypeToBaseProductType(product.type),
    };
}
export function createPinnedCarbonProject(project) {
    return {
        id: createPinnedItemId(project.id, PinnedItemType.CarbonProject),
        type: PinnedItemType.CarbonProject,
        projectId: project.id,
    };
}
export function createPinnedDriveItem(driveItem) {
    const base = {
        id: createPinnedItemId(driveItem.id, PinnedItemType.DriveItem),
        driveItemId: driveItem.id,
        driveItemType: driveItem.type,
        type: PinnedItemType.DriveItem,
    };
    if (driveItem.type === DriveItemType.Product) {
        return {
            ...base,
            productId: driveItem.productId,
            productType: driveItem.productType,
        };
    }
    else {
        return base;
    }
}
export function getProductFieldsFromPinnedItem(item) {
    if (item.type === PinnedItemType.Product) {
        return { productType: item.productType, productId: item.productId };
    }
    else if (item.type === PinnedItemType.DriveItem && item.productId && item.productType) {
        return { productType: item.productType, productId: item.productId };
    }
    else {
        return null;
    }
}
function createPinnedItemId(originId, type) {
    return `${type}-${originId}`;
}
export function getRequestDataFromPinnedItem(item) {
    switch (item.type) {
        case PinnedItemType.DriveItem:
            return { type: item.driveItemType, id: item.driveItemId };
        case PinnedItemType.CarbonProject:
            return { type: DriveItemType.CarbonProject, projectId: item.projectId };
        case PinnedItemType.Product:
            return { type: DriveItemType.Product, productId: item.productId };
    }
}
