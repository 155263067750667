import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useMemo } from 'react';
import { observer } from 'mobx-react';
import Modal from '@/common/components/Modal/Modal';
import DriveItemModalFooter from '@/common/components/TreeItemModal/DriveItemModalFooter/DriveItemModalFooter';
import TreeItemTitle from '@/common/components/TreeItemModal/TreeItemTitle/TreeItemTitle';
import { withStores } from '@/common/hoc/withStores';
import Notifier from '@/common/services/Notifier';
import { useAppStore } from '@/common/stores/AppStore';
import DriveItemModalForm from '@/drive/components/DriveItemModal/DriveItemModalForm/DriveItemModalForm';
import { useDriveItemModalForm } from '@/drive/components/DriveItemModal/useDriveItemModalForm';
import DriveItemPreview from '@/drive/components/DriveItemPreview/DriveItemPreview';
import { createEmptyForm } from '@/drive/types/driveItemModalFormData';
import AddToCollectionModalList from '@/forwarding/components/Forwarder/AddToCollectionModal/AddToCollectionModalList/AddToCollectionModalList';
import AddToCollectionModalListStore, { useAddToCollectionModalListStore, } from '@/forwarding/components/Forwarder/AddToCollectionModal/AddToCollectionModalList/AddToCollectionModalListStore';
import AddToCollectionModalStore, { useAddToCollectionModalStore, } from '@/forwarding/components/Forwarder/AddToCollectionModal/AddToCollectionModalStore';
import { isSmScreen } from '@/forwarding/components/Forwarder/ForwardModal/ForwardModal.utils';
import { MediaSize } from '@/media/constants/sizeCodes';
import styles from './AddToCollectionModal.module.less';
function AddToCollectionModal(props) {
    const { config, usePortal, onBack, onClose } = props;
    const { screenWidth } = useAppStore();
    const addToCollectionModalStore = useAddToCollectionModalStore();
    const listStore = useAddToCollectionModalListStore();
    const initialForm = useMemo(() => (config ? config.getForm() : createEmptyForm()), [config]);
    useEffect(() => {
        const params = config?.getCollectionReqData();
        if (params) {
            listStore.refresh(params.ids.length === 1 ? params.ids[0] : null, params.type);
        }
        else {
            listStore.reset();
        }
    }, [config]);
    const { values, errors, handleSubmit, setFieldValue } = useDriveItemModalForm(async () => {
        if (addToCollectionModalStore.isSubmitting) {
            return;
        }
        if (!listStore.selectedId) {
            Notifier.warn('Select Collection');
            return;
        }
        if (listStore.newCollectionTitle) {
            await listStore.finishCreation();
        }
        await addToCollectionModalStore.submit(config, values, listStore.selectedId);
        onClose();
    }, initialForm, !!config?.formDisabled());
    return (_jsxs(Modal, { isShowed: !!config, onClose: onClose, title: _jsx(TreeItemTitle, { filterShown: false, title: "Add to Collection", onBack: onBack }), usePortal: usePortal, fitWidthByContent: true, contentClassName: styles.addToCollectionModal, renderModalFooter: () => _jsx(DriveItemModalFooter, { addText: "Add", onAdd: listStore.startCreation }), children: [_jsx("div", { className: styles.treeContainer, children: _jsx(AddToCollectionModalList, {}) }), _jsx("div", { className: styles.formContainer, children: _jsxs("div", { className: styles.rightContainer, children: [config && (_jsx(DriveItemPreview, { preview: config.getPreview(), mediaSize: isSmScreen(screenWidth) ? MediaSize.XSmall : MediaSize.Small })), _jsx(DriveItemModalForm, { values: values, hideLabels: isSmScreen(screenWidth), errors: errors, disabled: config?.formDisabled(), setFieldValue: setFieldValue, notePlaceholder: isSmScreen(screenWidth) ? 'Type your note here' : '', onSubmit: handleSubmit, onReset: onClose })] }) })] }));
}
export default withStores(({ driveCollectionsStore }) => ({
    addToCollectionModalStore: new AddToCollectionModalStore(),
    addToCollectionModalListStore: new AddToCollectionModalListStore(driveCollectionsStore),
}), observer(AddToCollectionModal));
