import { jsx as _jsx } from "react/jsx-runtime";
import React, { Fragment } from 'react';
import { useToggle } from '@cutwise/utils';
import Popover from '../../../common/components/Popover/Popover';
import PatentList from './PatentList/PatentList';
import styles from './ShapeWithPatents.less';
const ShapeWithPatents = (props) => {
    const { cutShape, product } = props;
    const [isPopoverOpened, openPopover, closePopover] = useToggle();
    if (!product?.cutShape?.cutShapePatents || product.cutShape.cutShapePatents.length === 0) {
        return _jsx(Fragment, { children: cutShape });
    }
    return (_jsx(Popover, { isOpen: isPopoverOpened, preferPlace: "below", onOuterAction: closePopover, body: _jsx(PatentList, { cutShapePatents: product.cutShape.cutShapePatents }), tipSize: 0, className: "Popover--with-padding", children: _jsx("span", { className: styles.shapeWithPatents, onClick: openPopover, children: cutShape }) }));
};
export default ShapeWithPatents;
