import { arrayMove } from '@dnd-kit/sortable';
import { findItemDeep, getParentId } from '@/common/helpers/tree.utils';
export const iOS = /iPad|iPhone|iPod/.test(navigator.platform);
export function getDragDepth(offset, indentationWidth) {
    return Math.round(offset / indentationWidth);
}
export function getProjection(items, activeId, overId, dragOffset, indentationWidth) {
    const overItemIndex = items.findIndex(({ id }) => id === overId);
    const activeItemIndex = items.findIndex(({ id }) => id === activeId);
    const activeItem = items[activeItemIndex];
    const newItems = arrayMove(items, activeItemIndex, overItemIndex);
    const previousItem = newItems[overItemIndex - 1];
    const nextItem = newItems[overItemIndex + 1];
    const dragDepth = getDragDepth(dragOffset, indentationWidth);
    const projectedDepth = activeItem.depth + dragDepth;
    const maxDepth = getMaxDepth({ previousItem });
    const minDepth = getMinDepth({ nextItem });
    let depth = projectedDepth;
    if (projectedDepth >= maxDepth) {
        depth = maxDepth;
    }
    else if (projectedDepth < minDepth) {
        depth = minDepth;
    }
    return { depth, maxDepth, minDepth, parentId: getParentId(depth, previousItem, newItems, overItemIndex) };
}
export function getMaxDepth({ previousItem }) {
    return previousItem?.depth ?? 0;
}
export function getMinDepth({ nextItem }) {
    return nextItem?.depth ?? 0;
}
export function buildTree(flattenedItems) {
    const root = { id: 'root', children: [] };
    const nodes = { [root.id]: root };
    const items = flattenedItems.map((item) => ({
        ...item,
        original: { ...item.original, children: [] },
    }));
    const driveItems = items.map((item) => item.original);
    for (const item of items) {
        const { id, children = [], ...rest } = item.original;
        const parentId = item.parentId ?? root.id;
        const parent = nodes[parentId] ?? driveItems.find(({ id }) => id === parentId);
        nodes[id] = { id, children, ...rest };
        parent.children.push(item.original);
    }
    return root.children;
}
function countChildren(items, count = 0) {
    return items.reduce((acc, item) => {
        if (item.children) {
            return countChildren(item.children, acc + 1);
        }
        return acc + 1;
    }, count);
}
export function getChildCount(items, id) {
    const item = findItemDeep(items, id);
    return item?.children ? countChildren(item.children) : 0;
}
export function removeChildrenOf(items, ids) {
    const excludeParentIds = [...ids];
    return items.filter((item) => {
        if (item.parentId && excludeParentIds.includes(item.parentId)) {
            if (item.original.children?.length) {
                excludeParentIds.push(item.id);
            }
            return false;
        }
        return true;
    });
}
// If an item matches the predicate, return it with all its children;
// otherwise, filter its children recursively.
export function filterTreeDeep(items, predicate) {
    return items.flatMap((item) => {
        return predicate(item) ? item : filterTreeDeep(item?.children ?? [], predicate);
    });
}
