// extracted by mini-css-extract-plugin
var _1 = "xG2bUVFz7nBP9Jyu_nfq";
var _2 = "#76F366";
var _3 = "#EB8632";
var _4 = "#ECC834";
var _5 = "#21FC87";
var _6 = "#EA4A30";
var _7 = "#C7EA45";
var _8 = "#272B2B";
var _9 = "#BDBDBD";
var _a = "#7D7D7D";
var _b = "#6A0DAD";
var _c = "#3A65FF";
var _d = "#4A90E2";
var _e = "#ffffff";
var _f = "UDnIwoKzubY7SAxHCC5e";
var _10 = "HnkF5nEGAM7pw9H8Bt6g";
var _11 = "YgTquFsKqsKEXU4Rf5jd";
var _12 = "1200px";
var _13 = "992px";
var _14 = "1366px";
var _15 = "768px";
var _16 = "1475px";
var _17 = "576px";
var _18 = "1600px";
var _19 = "320px";
var _1a = "2500px";
var _1b = "wMxEXd52_9zxP79cnqzc";
var _1c = "t1sXgbDfnzObSlN76s7w";
var _1d = "oaDr_8P0NSwlaWZGfo8i";
export { _1 as "base-link", _2 as "colorGradeEX", _3 as "colorGradeFR", _4 as "colorGradeGD", _5 as "colorGradeOU", _6 as "colorGradePR", _7 as "colorGradeVG", _8 as "colorNeutral", _9 as "colorNeutralLighest", _a as "colorNeutralLight", _b as "colorNonOwnerSweetLine", _c as "colorOwnerSweetLine", _d as "colorRegularLinks", _e as "colorWhite", _f as "product-block-grid", _10 as "product-block-subgrid", _11 as "reset-button", _12 as "screenlg", _13 as "screenmd", _14 as "screenml", _15 as "screensm", _16 as "screenxlg", _17 as "screenxs", _18 as "screenxxlg", _19 as "screenxxs", _1a as "screenxxxlg", _1b as "selected", _1c as "single-page-responsive-font-size", _1d as "treeItemContainer" }
export default { "base-link": _1, "colorGradeEX": _2, "colorGradeFR": _3, "colorGradeGD": _4, "colorGradeOU": _5, "colorGradePR": _6, "colorGradeVG": _7, "colorNeutral": _8, "colorNeutralLighest": _9, "colorNeutralLight": _a, "colorNonOwnerSweetLine": _b, "colorOwnerSweetLine": _c, "colorRegularLinks": _d, "colorWhite": _e, "product-block-grid": _f, "product-block-subgrid": _10, "reset-button": _11, "screenlg": _12, "screenmd": _13, "screenml": _14, "screensm": _15, "screenxlg": _16, "screenxs": _17, "screenxxlg": _18, "screenxxs": _19, "screenxxxlg": _1a, "selected": _1b, "single-page-responsive-font-size": _1c, "treeItemContainer": _1d }
