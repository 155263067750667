export const iconNames = [
    '3d-cardboard',
    '3d-model',
    '3d-tv',
    '4v-360',
    '4v-crown',
    '4v-left',
    '4v-pavillion',
    '4v-right',
    '4v-side-view-table-down',
    '4v-side-view-table-up',
    'add-model-squared',
    'add-model',
    'add-person',
    'aperture',
    'arrow-bold-down',
    'arrow-bold-left',
    'arrow-bold-right',
    'arrow-lt-down',
    'arrow-lt-left',
    'arrow-lt-right',
    'arrow-lt-up',
    'arrow-mid-down',
    'arrow-mid-left',
    'arrow-mid-right',
    'arrow-mid-up',
    'arrow-selector-down',
    'arrow-selector-up',
    'arrows-asc',
    'arrows-desc',
    'available-data',
    'back-hand',
    'block',
    'bookmark-fill',
    'bookmark',
    'calendar',
    'cancelled',
    'carbon-project',
    'chat',
    'check',
    'clock-back',
    'clock',
    'close',
    'code',
    'communities',
    'compare-fill',
    'compare',
    'completed',
    'controls',
    'cube-rotation',
    'cube',
    'cut-brand',
    'cutwise-drive',
    'cutwise',
    'delete',
    'description-off',
    'description-on',
    'diamond-filled',
    'diamond',
    'distance-to-diamond',
    'distance-to-inclusions',
    'distance-to-rough',
    'documentation',
    'download',
    'drag-handle',
    'drag',
    'edit',
    'email',
    'expand',
    'facebook',
    'failed',
    'file-conversion',
    'filter',
    'folder',
    'full-protect',
    'fullscreen-off',
    'fullscreen-on',
    'grayscale',
    'gyro',
    'hamburger',
    'help',
    'history-event',
    'history',
    'inclusion',
    'inclusions-inside-old',
    'inclusions-inside',
    'info',
    'information',
    'jewelry',
    'leia-tablet',
    'lens-f-11',
    'lens-f-16',
    'lens-f-28',
    'lens-f-infinity',
    'lighting-d65',
    'lighting-fluorescent',
    'lighting-led',
    'lighting',
    'like-fill',
    'like',
    'link-add',
    'link-share',
    'link',
    'long-arrow-right',
    'marquise',
    'media',
    'metrics-brightness-faceup',
    'metrics-brightness',
    'metrics-brilliance',
    'metrics-carat',
    'metrics-clarity',
    'metrics-color-material',
    'metrics-color-table',
    'metrics-color',
    'metrics-culet',
    'metrics-cut-perf',
    'metrics-cut',
    'metrics-durability',
    'metrics-fire',
    'metrics-fluor-table',
    'metrics-fluor',
    'metrics-girdle',
    'metrics-ident',
    'metrics-identification',
    'metrics-measurments',
    'metrics-normalized–cut-perf',
    'metrics-optical-performance-area',
    'metrics-optical-performance',
    'metrics-optical-symmetry',
    'metrics-polish',
    'metrics-scintillation',
    'metrics-shape',
    'metrics-spread',
    'metrics-symmetry',
    'metrics-transparency',
    'models-linked',
    'more-circles',
    'more',
    'multistage',
    'my-lists',
    'next',
    'oval',
    'palette',
    'pause',
    'pencil',
    'pending',
    'pin-filled',
    'pin-off',
    'pin-on',
    'pin-unfilled',
    'pinterest',
    'play',
    'plus',
    'precise-fast',
    'prev',
    'princess',
    'printer',
    'private-access-for-users',
    'products-new',
    'products-removed',
    'project-shared',
    'project',
    'protect',
    'qr',
    'radar',
    'reprojection',
    'reset-camera',
    'rough-2',
    'rough-caverns',
    'rough-inclusions',
    'rough-shared',
    'rough-surface',
    'rough',
    'round',
    'search',
    'second-display',
    'selector-down',
    'selector-up',
    'settings-off',
    'settings-on',
    'share-outline',
    'share',
    'show-all',
    'singlescale-off',
    'singlescale-on',
    'sort-down',
    'sort-up',
    'spectrum-cuboid',
    'spectrum-cushion-fancy',
    'spectrum-cushion',
    'spectrum-emerald',
    'spectrum-heart',
    'spectrum-lighting-d65',
    'spectrum-lighting-fluorescent',
    'spectrum-lighting-led',
    'spectrum-lighting',
    'spectrum-marquise',
    'spectrum-other',
    'spectrum-oval-fancy',
    'spectrum-oval',
    'spectrum-pear',
    'spectrum-princess',
    'spectrum-radiant',
    'spectrum-rough',
    'spectrum-round',
    'star-outline',
    'star',
    'swap',
    'theme-dark',
    'theme-light',
    'touch-inclusions',
    'user-billing-eur',
    'user-billing-usd',
    'user-import',
    'user-logout',
    'user-my-collections',
    'user-profile',
    'view-big',
    'view-chart',
    'view-combo-lg',
    'view-combo',
    'view-ex-small',
    'view-little',
    'view-small',
    'view-tab',
    'warning',
    'wechat',
    'whatsapp',
    'window',
    'x-twitter',
    'zoom-in',
    'zoom-out',
    'zoom-to-fit',
    'deadzone',
    'folder-add',
    'folder-move',
];
const iconNameSet = new Set(iconNames);
export function isIconName(name) {
    return iconNameSet.has(name);
}
