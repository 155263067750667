export var DriveModifierEventType;
(function (DriveModifierEventType) {
    DriveModifierEventType["Delete"] = "Delete";
    DriveModifierEventType["Create"] = "Create";
    DriveModifierEventType["Update"] = "Update";
    DriveModifierEventType["BatchAdd"] = "BatchAdd";
    DriveModifierEventType["BatchMove"] = "BatchMove";
})(DriveModifierEventType || (DriveModifierEventType = {}));
export var OptimisticUpdatePhase;
(function (OptimisticUpdatePhase) {
    OptimisticUpdatePhase["Apply"] = "Apply";
    OptimisticUpdatePhase["Error"] = "Error";
    OptimisticUpdatePhase["Success"] = "Success";
})(OptimisticUpdatePhase || (OptimisticUpdatePhase = {}));
