import { observable, computed } from 'mobx';
import {
  TYPE_DIAMOND_COLORLESS,
  TYPE_JEWELRY,
  TYPE_LGD_ROUGH,
  TYPE_ROUGH,
  TYPE_GEM,
} from '@/product/constants/productTypes';
import Sort from '@/common/state/Sort';
import { User } from '@/user/entity/User';
import ProductViewSettings from '@/product/entity/ProductViewSettings';
import ProductFactory from '@/product/entity/ProductFactory';
import { ContactDataLink } from '@/contacts/entity/ContactDataLink';
import { formatDate } from '@/common/helpers/formatDate';
import { filterProductsWithStereo } from '@/collection/helpers/filterProductsWithStereo';
import { viewTemplateRepository } from '../repository/ViewTemplateRepository';
import { CollectionType } from '@/collection/entity/CollectionType';

/**
 * @typedef {Object} CollectionPlainObject
 * @property {Object} id
 * @property {string} createdAt
 * @property {string} updatedAt
 * @property {number} type
 * @property {string} title
 * @property {?string} description
 * @property {?boolean} active
 * @property {?ViewSettingsPlainObject} settings
 * @property {?number} userId
 * @property {?number[]} products
 * @property {?ContactDataLink} contactDataLink
 * @property {?[{href: string, rel: string}]} _links
 */

export const COLLECTION_TYPE_DIAMOND = CollectionType.Diamond;
export const COLLECTION_TYPE_JEWELRY = CollectionType.Jewelry;
export const COLLECTION_TYPE_ROUGH = CollectionType.Rough;
export const COLLECTION_TYPE_GEM = CollectionType.Gem;

export const COLLECTION_MAX_COUNT = 100;

export default class Collection {
  /**
   * @type {string} id
   */
  id;

  /**
   * @type {string} title
   */
  @observable
  title = '';

  /**
   * @type {string} description
   */
  @observable
  description = '';

  /**
   * @type {ProductViewSettings} settings
   */
  @observable
  settings;

  /**
   * @type {ProductViewSettings} defaultSettings
   */
  @observable
  defaultSettings;

  /**
   * @type {boolean} is it public collection or private (like wish list)
   */
  @observable
  public;

  /**
   * @type {Product[]}
   */
  @observable
  products = [];

  /**
   * @type {number}
   */
  @observable
  type;

  /**
   * @type {?User}
   */
  user = null;

  /**
   * @type {string}
   */
  createdAt;

  /**
   * @type {?string}
   */
  updatedAt;

  /**
   * @type {number[]}
   */
  manualSorting;

  /**
   * @type {ContactDataLink}
   */
  @observable
  contactDataLink;

  /**
   * @type {?string}
   */
  @observable
  dilogLink;

  /**
   * @type {string}
   */
  @observable
  productType;

  /**
   * @type {string} productType
   * @return {number}
   */
  static getTypeByProductType(productType) {
    if (productType === TYPE_JEWELRY) {
      return COLLECTION_TYPE_JEWELRY;
    }

    if (productType === TYPE_ROUGH || productType === TYPE_LGD_ROUGH) {
      return COLLECTION_TYPE_ROUGH;
    }

    if (productType === TYPE_GEM) {
      return COLLECTION_TYPE_GEM;
    }

    return COLLECTION_TYPE_DIAMOND;
  }

  constructor(modelData = {}, productType = TYPE_DIAMOND_COLORLESS) {
    this.assignFrom(modelData, productType);
  }

  /**
   * @return {number}
   */
  @computed
  get length() {
    return this.products ? this.products.length : 0;
  }

  /**
   * @return {?boolean}
   */
  @computed
  get isEmpty() {
    return !this.products || this.products.length === 0;
  }

  /**
   * @return {number[]}
   */
  @computed
  get productIds() {
    return this.products ? this.products.map(p => p.id) : [];
  }

  /**
   * @return {?boolean}
   */
  @computed
  get isLoaded() {
    return this.products && this.products.every(p => p.isLoaded);
  }

  /**
   * @return {string}
   */
  @computed
  get readableStatus() {
    return this.public === true ? 'Public' : 'Private';
  }

  /**
   * @return {?string}
   */
  @computed
  get createdAtFormatted() {
    return this.createdAt ? formatDate.toHumanDate(this.createdAt) : null;
  }

  @computed
  get activeSetupPreset() {
    const spList = this.products
      .map(p => (
        p.mediaCollection ? p.mediaCollection.getSuitableSPFromSPList(this.settings.resultedSetupPresets) : null
      ))
      .filter(sp => Boolean(sp));

    return spList?.[0]?.id ?? null;
  }

  /**
   * @return {Product[]}
   */
  @computed
  get productsWithStereo() {
    return filterProductsWithStereo(this.products, this.settings.resultedSetupPresets);
  }

  /**
   * @return {boolean}
   */
  @computed
  get hasStereo() {
    return this.productsWithStereo.length === this.products.length;
  }

  /**
   * @param {Product} product
   * @return {boolean}
   */
  hasProduct(product) {
    if (!this.products || this.products.length === 0) {
      return false;
    }

    return Boolean(this.getProductById(product.id));
  }

  /**
   * @param {number} id
   * @return {Product}
   */
  getProductById = (id) => {
    return this.products.find(p => p.id === id);
  }

  /**
   * @param {Product} product
   * @return {?Product}
   */
  prevProductFor(product) {
    if (!this.hasProduct(product)) {
      return null;
    }

    const i = this.products.findIndex(p => p.id === product.id);
    if (i === 0) {
      return this.products[this.products.length - 1];
    }

    return this.products[i - 1];
  }

  /**
   * @param {Product} product
   * @return {?Product}
   */
  nextProductFor(product) {
    if (!this.hasProduct(product)) {
      return null;
    }

    const i = this.products.findIndex(p => p.id === product.id);
    if (i === this.products.length - 1) {
      return this.products[0];
    }

    return this.products[i + 1];
  }

  /**
   * @return {typeof CollectionPlainObject}
   */
  serialize() {
    const json = {
      title: this.title,
      description: this.description,
    };

    if (this.settings) {
      json.settings = this.settings.serialize();
    }

    return json;
  }

  /**
   * @param {number} collectionType
   * @return {{isSingleScale: boolean, isGrayscale: boolean}|{}} // todo
   */
  static getForcedCollectionSettings(collectionType) {
    if (collectionType === COLLECTION_TYPE_JEWELRY) {
      return {
        isSingleScale: false,
        isGrayscale: false,
      };
    }

    return {};
  }

  /**
   * Copy contents of plain object to entity instance
   * @param {CollectionPlainObject} modelData
   * @param {?string} productType
   * @return {Collection}
   */
  assignFrom(modelData, productType = TYPE_DIAMOND_COLORLESS) {
    this.productType = productType;

    // todo should use Sort object
    const hasSort = modelData && modelData.settings && modelData.settings.sort;
    if (hasSort && modelData.settings.sort[Sort.SORT_MEDIA_RELEVANCE]) {
      delete modelData.settings.sort[Sort.SORT_MEDIA_RELEVANCE];
      modelData.settings.sort[Sort.SORT_MANUAL] = Sort.DIRECTION_DESC;
    }

    if (modelData.id !== undefined) {
      this.id = modelData.id;
    }
    if (modelData.createdAt !== undefined) {
      this.createdAt = modelData.createdAt;
    }
    if (modelData.updatedAt !== undefined) {
      this.updatedAt = modelData.updatedAt;
    }
    if (modelData.type !== undefined) {
      this.type = modelData.type;
    }
    if (modelData.title !== undefined) {
      this.title = modelData.title;
    }
    if (modelData.description !== undefined) {
      this.description = modelData.description;
    }
    if (modelData.public !== undefined) {
      this.public = modelData.public;
    }
    if (modelData.userId !== undefined) {
      this.user = new User({ id: modelData.userId });
    }
    if (modelData.contactDataLink) {
      this.contactDataLink = new ContactDataLink(modelData.contactDataLink);
    }
    if (modelData._links) {
      const dilogLinkData = modelData._links.find(({ rel }) => rel === 'dilog.report');

      if (dilogLinkData && dilogLinkData.href) {
        this.dilogLink = dilogLinkData.href;
      }
    }

    const forcedSettings = Collection.getForcedCollectionSettings(modelData.type);
    if (modelData.settings !== undefined) {
      this.settings = new ProductViewSettings(
        {
          ...modelData.settings,
          viewTemplate: modelData.settings.viewTemplate || (modelData.settings.viewTemplate && modelData.settings.viewTemplate.id),
        },
        forcedSettings,
      );
    } else {
      this.settings = new ProductViewSettings({ viewTemplate: viewTemplateRepository.getDefaultViewTemplateId(productType) }, forcedSettings);
    }

    this.defaultSettings = new ProductViewSettings(this.settings.serialize());

    if (modelData.products) {
      this.products = modelData.products.map(p => ProductFactory.make(productType, p));
      this.setManualSorting(this.products.map(p => p.id));
    }

    return this;
  }

  /**
   * @param {number[]} order
   */
  setManualSorting(order) {
    this.manualSorting = order;
  }
}
