var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { observable, toJS } from 'mobx';
import diff from '@/common/helpers/diff';
import Sort from '@/common/state/Sort';
import { ProductType } from '@/product/constants/productTypes';
import { ViewTemplateType } from '../constants/viewTemplateTypes';
export default class ViewTemplate {
    id;
    title;
    setupPreset;
    setupPresetGroup;
    sort;
    cardFields;
    isHiddenDesc;
    isSingleScale;
    isGrayscale;
    isSaturation;
    type;
    context;
    static getTypeByProductType(productType) {
        if (productType === ProductType.Rough || productType === ProductType.LGDRough) {
            return ViewTemplateType.Rough;
        }
        if (productType === ProductType.Jewelry) {
            return ViewTemplateType.Jewelry;
        }
        if (productType === ProductType.DiamondFancy) {
            return ViewTemplateType.Fancy;
        }
        if (productType === ProductType.Gem) {
            return ViewTemplateType.Gem;
        }
        return ViewTemplateType.Colorless;
    }
    constructor(modelData, setupPreset = null, setupPresetGroup = null) {
        this.id = modelData.id;
        this.title = modelData.title;
        this.sort = Sort.fromJSON(modelData.sort);
        this.cardFields = modelData.cardFields;
        this.setupPreset = setupPreset;
        this.setupPresetGroup = setupPresetGroup;
        this.isHiddenDesc = modelData.isHiddenDesc;
        this.isSingleScale = modelData.isSingleScale;
        this.isGrayscale = modelData.isGrayscale;
        this.isSaturation = modelData.isSaturation;
        this.type = modelData.type;
        this.context = modelData.context;
    }
    diff(otherObject) {
        return diff(toJS(this), toJS(otherObject));
    }
}
__decorate([
    observable
], ViewTemplate.prototype, "id", void 0);
__decorate([
    observable
], ViewTemplate.prototype, "title", void 0);
__decorate([
    observable
], ViewTemplate.prototype, "setupPreset", void 0);
__decorate([
    observable
], ViewTemplate.prototype, "setupPresetGroup", void 0);
__decorate([
    observable
], ViewTemplate.prototype, "sort", void 0);
__decorate([
    observable
], ViewTemplate.prototype, "cardFields", void 0);
__decorate([
    observable
], ViewTemplate.prototype, "isHiddenDesc", void 0);
__decorate([
    observable
], ViewTemplate.prototype, "isSingleScale", void 0);
__decorate([
    observable
], ViewTemplate.prototype, "isGrayscale", void 0);
__decorate([
    observable
], ViewTemplate.prototype, "isSaturation", void 0);
__decorate([
    observable
], ViewTemplate.prototype, "type", void 0);
