// extracted by mini-css-extract-plugin
var _1 = "VPwk0NzWbqGZwQIjjXfc";
var _2 = "Ba9nXON4ppdAW5fLNPlV";
var _3 = "B5Gr4srPprorS5SbL5ML";
var _4 = "qqiPIihPkHCUzCzJOd1H";
var _5 = "#76F366";
var _6 = "#EB8632";
var _7 = "#ECC834";
var _8 = "#21FC87";
var _9 = "#EA4A30";
var _a = "#C7EA45";
var _b = "#272B2B";
var _c = "#BDBDBD";
var _d = "#7D7D7D";
var _e = "#6A0DAD";
var _f = "#3A65FF";
var _10 = "#4A90E2";
var _11 = "#ffffff";
var _12 = "as1oF5JLDr_5ctTtg15R";
var _13 = "kTHsmeLeRGvGYWosTkuQ";
var _14 = "jscYnkTkGWuHWXTnNtEw";
var _15 = "ODT4CemxS4hUY010iFow";
var _16 = "Bd9rcVFM3tkBhqhDJr56";
var _17 = "v_7WYWPaFBs2c18Tcq1v";
var _18 = "OhRAqrxxmvv3aIL1chD3";
var _19 = "WqimKbuDZ2hVPcpw2x8u";
var _1a = "1200px";
var _1b = "992px";
var _1c = "1366px";
var _1d = "768px";
var _1e = "1475px";
var _1f = "576px";
var _20 = "1600px";
var _21 = "320px";
var _22 = "2500px";
var _23 = "Hvo11ubugC3TVUHb5Cin";
var _24 = "U4m_XSJxBy32DyYf7vti";
export { _1 as "animatedDropdown", _2 as "animating", _3 as "base-link", _4 as "closed", _5 as "colorGradeEX", _6 as "colorGradeFR", _7 as "colorGradeGD", _8 as "colorGradeOU", _9 as "colorGradePR", _a as "colorGradeVG", _b as "colorNeutral", _c as "colorNeutralLighest", _d as "colorNeutralLight", _e as "colorNonOwnerSweetLine", _f as "colorOwnerSweetLine", _10 as "colorRegularLinks", _11 as "colorWhite", _12 as "content", _13 as "label", _14 as "labelText", _15 as "menuContainer", _16 as "opened", _17 as "product-block-grid", _18 as "product-block-subgrid", _19 as "reset-button", _1a as "screenlg", _1b as "screenmd", _1c as "screenml", _1d as "screensm", _1e as "screenxlg", _1f as "screenxs", _20 as "screenxxlg", _21 as "screenxxs", _22 as "screenxxxlg", _23 as "single-page-responsive-font-size", _24 as "triangle" }
export default { "animatedDropdown": _1, "animating": _2, "base-link": _3, "closed": _4, "colorGradeEX": _5, "colorGradeFR": _6, "colorGradeGD": _7, "colorGradeOU": _8, "colorGradePR": _9, "colorGradeVG": _a, "colorNeutral": _b, "colorNeutralLighest": _c, "colorNeutralLight": _d, "colorNonOwnerSweetLine": _e, "colorOwnerSweetLine": _f, "colorRegularLinks": _10, "colorWhite": _11, "content": _12, "label": _13, "labelText": _14, "menuContainer": _15, "opened": _16, "product-block-grid": _17, "product-block-subgrid": _18, "reset-button": _19, "screenlg": _1a, "screenmd": _1b, "screenml": _1c, "screensm": _1d, "screenxlg": _1e, "screenxs": _1f, "screenxxlg": _20, "screenxxs": _21, "screenxxxlg": _22, "single-page-responsive-font-size": _23, "triangle": _24 }
