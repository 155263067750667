import { upperFirst } from 'lodash';
import { getProductFieldsFromPinnedItem } from '@/forwarding/types/pinnedItem';
import { isDiamond, ProductType } from '@/product/constants/productTypes';
export function getProductTypeText(productType) {
    return isDiamond(productType) ? 'Diamonds' : upperFirst(productType);
}
export function getProductTypeAndIdsPairs(items) {
    const productMap = items.reduce((acc, item) => {
        const productFields = getProductFieldsFromPinnedItem(item);
        if (!productFields) {
            return acc;
        }
        const { productType, productId } = productFields;
        const ids = acc.get(productType) ?? [];
        ids.push(productId);
        acc.set(productType, ids);
        return acc;
    }, new Map());
    return sortByProductType([...productMap.entries()]);
}
const order = [ProductType.Diamond, ProductType.Rough, ProductType.Jewelry];
function sortByProductType(entries) {
    return entries.sort((a, b) => {
        const indexA = order.indexOf(a[0]);
        const indexB = order.indexOf(b[0]);
        return indexA - indexB;
    });
}
