import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { observer } from 'mobx-react';
import cls from 'classnames';
import Icon from '@/common/components/Icon/Icon';
import TreeItemContainer from '@/common/components/ModalTreeItem/TreeItemContainer/TreeItemContainer';
import styles from './CollectionListItem.module.less';
function CollectionListItem(props) {
    const { title, disabled, isSelected, onClick } = props;
    return (_jsxs(TreeItemContainer, { isSelected: isSelected, onClick: disabled ? undefined : onClick, className: cls(styles.collectionListItem, { [styles.disabled]: disabled, [styles.selected]: isSelected }), children: [_jsx(Icon, { name: "like", className: styles.icon }), title] }));
}
export default observer(CollectionListItem);
