import { isString } from '@cutwise/utils';
import { NETBOX_HOSTNAME } from '@/common/network/CutwiseAPIClient';
import Storage from '@/common/services/Storage';
import { netBoxStore } from '@/netbox/stores/NetBoxStore';
export function serializeDimensions(dimensions) {
    const dimensionGA = {
        dimension9: window.location.pathname,
    };
    if (netBoxStore.isNetBoxActive) {
        dimensionGA.dimension10 = true;
        const hostname = Storage.getObject(NETBOX_HOSTNAME, Storage.STORAGE_LOCAL);
        if (isString(hostname)) {
            const hostnameValue = hostname.trim();
            if (hostnameValue) {
                dimensionGA.dimension11 = hostnameValue;
            }
        }
    }
    if (!dimensions) {
        return dimensionGA;
    }
    const { b2bId, id, b2bSid, collectionId, ...rest } = dimensions;
    if (b2bId) {
        dimensionGA.dimension1 = b2bId.toString();
    }
    if (id) {
        dimensionGA.dimension2 = id.toString();
    }
    if (b2bSid) {
        dimensionGA.dimension7 = b2bSid.toString();
    }
    if (collectionId) {
        dimensionGA.dimension8 = collectionId.toString();
    }
    return {
        ...dimensionGA,
        ...rest,
    };
}
