import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment, useCallback, useState } from 'react';
import { observer } from 'mobx-react';
import DriveItemModal from '@/drive/components/DriveItemModal/DriveItemModal';
import { useDriveItemModal } from '@/drive/components/DriveItemModal/useDriveItemModal';
import AddToCollectionModal from '@/forwarding/components/Forwarder/AddToCollectionModal/AddToCollectionModal';
import { useForwarder } from '@/forwarding/components/Forwarder/useForwarder';
import ForwardModal from './ForwardModal/ForwardModal';
import QrCodeModal from './QrCodeModal/QrCodeModal';
function Forwarder(props) {
    const { usePortal, config, onClose, onDriveForwardSuccess } = props;
    const [qrUrl, setQrUrl] = useState(null);
    const handleCloseDriveModal = useCallback(() => {
        closeDriveItemModal();
        onClose();
    }, [onClose]);
    const handleCloseAddToCollectionModal = useCallback(() => {
        closeAddToCollectionModal();
        onClose();
    }, [onClose]);
    const [openAddToCollectionModal, closeAddToCollectionModal, addToCollectionModalConfig] = useForwarder();
    const [openDriveItemModal, closeDriveItemModal, driveItemModalConfig] = useDriveItemModal();
    const handleQrClose = useCallback(() => {
        setQrUrl(null);
        onClose();
    }, [onClose]);
    return (_jsxs(Fragment, { children: [_jsx(AddToCollectionModal, { config: addToCollectionModalConfig, onClose: handleCloseAddToCollectionModal, onBack: closeAddToCollectionModal, usePortal: usePortal }), _jsx(DriveItemModal, { config: driveItemModalConfig, onClose: handleCloseDriveModal, onSuccess: onDriveForwardSuccess, usePortal: usePortal, onBack: closeDriveItemModal }), _jsx(QrCodeModal, { href: qrUrl, onClose: handleQrClose, onBack: () => setQrUrl(null), usePortal: usePortal }), _jsx(ForwardModal, { config: config && !qrUrl && !driveItemModalConfig && !addToCollectionModalConfig ? config : null, usePortal: usePortal, onClose: onClose, onDriveForwardSuccess: onDriveForwardSuccess, openDriveItemModal: openDriveItemModal, openAddToCollectionModal: openAddToCollectionModal, onQrClick: setQrUrl })] }));
}
export default observer(Forwarder);
